import { useId, useState } from "react";
import validations from "./inputValidations";

import './TextInput.scss';

const TextInput = (props) => {
  const [focused, setFocused] = useState(false);
  const [blur, setBlur] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { label, errorMessage, validate, events, autoFocus, className, innerRef, ...inputProps } = props;
  const { onChange, onBlur, onClick, onKeyDown } = events;
  const inputId = useId(); 

  const handleFocus = (e) => { setFocused(true); };
  const handleBlur = (e) => { setBlur(true); };

  const element = props.type === "textarea" ? (
    <textarea
      {...inputProps}
      id={inputId}
      onChange={(e) => {
        const ifValid = validate ? validations[validate].rule(e).test(e.target.value.toString()) : true;
        setIsValid(ifValid);
        onChange && onChange({...e, isValid: ifValid});
      }}
      onBlur={(e) => {
        const ifValid = validate ? validations[validate].rule(e).test(e.target.value.toString()) : true;
        setIsValid(ifValid);
        handleBlur();  
        onBlur &&  onBlur({...e, isValid: ifValid});        
      }}
      onClick={onClick}
      onKeyDown={onKeyDown}
      autoFocus={autoFocus && autoFocus}
      onFocus={(data) => handleFocus(data)}
      focused={autoFocus ? focused.toString() : undefined}
    />
  ) : (
    <input
      {...inputProps}
      id={inputId}      
      onChange={(e) => {
        const ifValid = validate ? validations[validate].rule(e).test(e.target.value.toString()) : true;
        setIsValid(ifValid);
        onChange && onChange({...e, isValid: ifValid});
      }}
      onBlur={(e) => {
        inputProps.name === "confirmPassword" && setFocused(true)
        const ifValid = validate ? validations[validate].rule(e).test(e.target.value.toString()) : true;
        setIsValid(ifValid);
        handleBlur();  
        onBlur && onBlur({...e, isValid: ifValid});
      }}
      className={className}
      ref={innerRef}
      onClick={onClick}
      onKeyDown={onKeyDown}
      autoFocus={autoFocus && autoFocus}
      onFocus={(data) => handleFocus(data)}
      focused={autoFocus ? focused.toString() : undefined}
    />    
  );

  return (
    <div className={`formInput ${!isValid && blur && "hasError"} ${className ? className : ''}`}>
        {label && <label htmlFor={inputId}>{label}</label>}
        {element}
        {!isValid && blur && <span className="fieldError">{ validations[validate]?.errorMessage }</span> }
    </div>
  );
};

export default TextInput;