import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateUserDefaults, getUserDefaults } from '../../actions/userDefaults';
import TextInput from '../UI/Input/TextInput'

const UserDefaults = ({user}) => {
    const { defaults, isLoading } = useSelector((state) => state.userDefaults);   
    const dispatch = useDispatch();
    const [userDefaults, setUserDefaults] = useState();
   
    useEffect(() => {
        dispatch(getUserDefaults(user));
    }, [dispatch, user]);

    useEffect(() => {
        setUserDefaults(defaults);
    }, [defaults]);

    const handleChange = (e) => {
        setUserDefaults(prevState => {
          return { ...prevState, hourlyRate: e.target.value }
        });
    };

    const handleSubmit = (data) => {
        if(defaults.hourlyRate === Number(userDefaults.hourlyRate) || !userDefaults.hourlyRate ) return;
        dispatch(updateUserDefaults(userDefaults));
    };  
    
    return (
        <TextInput
            type="text"
            id="hourlyRate"
            name="hourlyRate"
            placeholder="Hourly Rate"
            validate="required"
            value={userDefaults?.hourlyRate ?? 0}
            label="Hourly Rate"
            required
            events={{
                onChange: data => handleChange(data),
                onBlur: data => handleSubmit(data),
                onKeyDown: data => data.key === 'Enter' && data.target.blur()
            }}
        />
    )
}

export default UserDefaults