import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Navigate } from 'react-router-dom';
import RootLayout from './pages/RootLayout/RootLayout';
import Auth from './components/Auth/Auth';
import NotFound from './components/NotFound/NotFound';
import { useSelector } from 'react-redux';
import ProjectsPage from './pages/ProjectsPage/ProjectsPage';
import ClientsPage from './pages/ClientsPage/ClientsPage';
import TransactionsPage from './pages/TransactionsPage/TransactionsPage';
import ProjectDetails from './components/Projects/ProjectDetails/ProjectDetails';

// import logo from './logo.svg';
import './scss/style.scss';
import TimerPage from './pages/TimerPage/TimerPage';
import ConfirmEmail from './components/Auth/ConfirmEmail/ConfirmEmail';
import PasswordReset from './components/Auth/PasswordReset/PasswordReset';
import SettingsPage from './pages/SettingsPage/SettingsPage';

// import ReactGA from 'react-ga';

// const TRACKING_ID = "G-9W9EXCKJF6"; // YOUR_OWN_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

function App() { 
  const user = useSelector((state) => state.auth.user);
  
  const router = createBrowserRouter(createRoutesFromElements(
    <Route path='/' element={<RootLayout />}>
      <Route path='/' element={!user ? <Navigate to="/auth" /> : <Navigate to="/timer" />}></Route>
      <Route path='/timer' element={!user ? <Navigate to="/auth" /> : <TimerPage />}></Route>
      <Route path='/projects' element={!user ? <Navigate to="/auth" /> : <ProjectsPage />}></Route>
      <Route path='/projects/search' element={!user ? <Navigate to="/auth" /> : <ProjectsPage />}></Route>
      <Route path='/projects/:id' element={!user ? <Navigate to="/auth" /> : <ProjectDetails />}></Route>
      <Route path='/transactions' element={!user ? <Navigate to="/auth" /> : <TransactionsPage />}></Route>
      <Route path='/clients' exact element={!user ? <Navigate to="/auth" /> : <ClientsPage />}></Route>
      <Route path='/auth' element={!user ? <Auth /> : <Navigate to="/projects" />}></Route>
      <Route path='/settings' element={!user ? <Auth /> : <SettingsPage />}></Route>
      <Route path='/confirm-email/' element={<ConfirmEmail />}></Route>
      <Route path='/confirm-email/:token' element={<ConfirmEmail />}></Route>
      <Route path='/password-reset/' element={<PasswordReset />}></Route>
      <Route path='/password-reset/:token' element={<PasswordReset />}></Route>
      <Route path='*' element={!user ? <Navigate to="/auth"/> : <NotFound />}></Route>
    </Route>
  ));

  return (  
    // <GoogleOAuthProvider clientId="671638482179-7spbg28vk3rbfb6cojs4s8buabm5knok.apps.googleusercontent.com">
      <RouterProvider router={router} />
    // </GoogleOAuthProvider>
  );
}

export default App;
