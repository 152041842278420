import React from "react"
import ContentLoader from "react-content-loader"

const ProjectFormLoader = (props) => (
    <ContentLoader 
    speed={2}
    width={1920}
    height={94}
    viewBox="0 0 1920 94"
    style={{width: '100%'}}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="6" y="39" rx="0" ry="0" width="1031" height="40" /> 
    <rect x="1076" y="39" rx="0" ry="0" width="210" height="40" /> 
    <rect x="1326" y="39" rx="0" ry="0" width="96" height="40" /> 
    <circle cx="1749" cy="60" r="25" /> 
    <circle cx="1800" cy="48" r="11" /> 
    <rect x="1427" y="39" rx="0" ry="0" width="96" height="40" /> 
    <circle cx="1799" cy="75" r="11" /> 
    <rect x="1584" y="39" rx="0" ry="0" width="96" height="40" />
  </ContentLoader>
)

export default ProjectFormLoader