import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const useHooks = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();  
    const query = useQuery();
    const [page, setPage] = useState(query.get('page') || 1);  
    const [limit, setLimit] = useState(15);
    const { user } = useSelector((state) => state.auth);
    return {dispatch, navigate, query, page, setPage, limit, setLimit, user};
};