import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { ERROR } from '../../constants/actionTypes';
import PasswordChangeForm from './PasswordChangeForm/PasswordChangeForm';

const PasswordChange = ({user}) => {
    const dispatch = useDispatch();       
    const [passwordModal, setPasswordModal] = useState(false);

    const showModal = (e) => {
        e.preventDefault();
        setPasswordModal(true);
    };

    const hidePasswordModal = (e) => {
        e.preventDefault();        
        setPasswordModal(false);
        dispatch({ type: ERROR, data: [] });
    };

    return (
        <div className='passwordChange'>
            <button className="button" onClick={showModal}>Change Password</button>
            {passwordModal &&
                <PasswordChangeForm
                    setPasswordModal={(passwordModal) => setPasswordModal(passwordModal)}
                    user={user}
                    passwordModal={passwordModal}
                    onClose={hidePasswordModal} 
                />
            }
        </div>
    )
}

export default PasswordChange