import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { createProject, updateProject } from '../../../actions/projects';
import { getUserDefaults } from '../../../actions/userDefaults';
import { FLASH_MESSAGE } from '../../../constants/actionTypes';
import ComponentLoader from '../../Loaders/ComponentLoader/ComponentLoader';
import TextInput from '../../UI/Input/TextInput';
import Modal from '../../UI/Modal/Modal';
import Select from '../../UI/Select/Select';

const initialState = { 
    title: '',
    description: '',
    client: '',
    hourlyRate: '',
    creator: '', 
    totalHours: 0,        
    paidHours: 0,        
    totalAmount: 0,        
    totalPaidAmount: 0,        
};

const ProjectForm = ({ currentId, setCurrentId, onClose, page, sort, limit, setProjectModal, clients }) => {
  const [projectData, setProjectData] = useState(initialState);
  const [showErrors, setShowErrors] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const { errors, isLoading } = useSelector((state) => state.projects);
  const { defaults } = useSelector((state) => state.userDefaults);
  const project = useSelector((state) => (currentId ? state.projects.projects.find((message) => message._id === currentId) : null));
  const dispatch = useDispatch();
  const editMode = (currentId !== 0);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getUserDefaults(user));        
  }, []);    

  useEffect(() => {

    if (project) setProjectData(project);
    if (!project) setProjectData({...projectData, hourlyRate: defaults?.hourlyRate ?? ''});

  }, [project, dispatch, editMode, defaults]);

  useEffect(() => {

    errors?.response && setShowErrors(true);
    !errors?.response && successMessage && clear();

  }, [errors]);

  useEffect(() => {
    if(showErrors) {
      setTimeout( () => setShowErrors(false), 3000);
    }
  }, [showErrors]);

  const clear = () => {
    !editMode && setCurrentId(0);
    setProjectData(initialState);
    setProjectModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!editMode) {
      dispatch(createProject({...projectData, name: user?.firstname, limit: limit, page: page, sort: sort}));
    } else {
      dispatch(updateProject(currentId, {...projectData, currentUser: user?._id, oldTitle: project.title}));
    }

    setSuccessMessage(true);
  };    

  const handleChange = (e) => {
    setProjectData(prevState => { 
      return { ...prevState, [e.target.name] : e.target.value }
    });
  }

  return (
    <>
    <Modal onClose={onClose} title={editMode ? 'Edit project' : 'Add new project'}>
      <form onSubmit={handleSubmit}>
        {isLoading && <ComponentLoader />}  
        <TextInput
          type="text"
          label="Name"
          placeholder="Project name"
          id="title"
          name="title"
          validate="required"
          value={projectData.title}
          required
          events={{
            onChange: data => handleChange(data)
          }}
        />
        <TextInput
          type="number"
          id="hourlyRate"
          name="hourlyRate"
          placeholder="Hourly Rate"
          label="Hourly Rate"
          validate="numeric"
          value={projectData.hourlyRate ?? defaults.hourlyRate}
          required
          events={{
            onChange: data => handleChange(data)
          }}
        />  
        <Select 
          name="client" 
          label="Client"
          keyvalue="name"
          options={clients} 
          required
          value={projectData.client?._id ? projectData?.client?._id : projectData.client} 
          onChange={handleChange} 
          nodata={{
            ctaLink: '/clients',
            ctaText: 'Add client',
            textAlert: 'No clients added',
          }}          
        />   
        <TextInput
          type="textarea"
          id="description"
          name="description"
          label="Description"
          placeholder="Description"
          value={projectData.description}
          events={{
            onChange: data => handleChange(data)
          }}
        />                       
        <button type='submit' className='button primary full-width'>{editMode ? 'Edit project' : 'Add new project'}</button>    
        {errors?.message && showErrors && <div className='form__global-errors'>{errors.response.data.message}</div>}
      </form>
      
    </Modal>
    </>
  )
}

export default ProjectForm