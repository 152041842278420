import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

import { getTransactions } from '../../actions/transactions';
import { ERROR } from '../../constants/actionTypes';
import { useHooks } from '../../utils/hooks';
import { formatter } from '../../utils/utils';
import ProjectsLoader from '../Loaders/ProjectLoader/ProjectsLoader';
import Pagination from '../Pagination/Pagination';
import Transaction from './Transaction/Transaction';
import TransactionForm from './TransactionForm/TransactionForm';

const Transactions = ({ setTransactionModal, transactionModal }) => {
  const { dispatch, navigate, page, setPage, query, limit, user } = useHooks();  
  const { transactions, isLoading, numberOfPages, totalAmount } = useSelector((state) => state.transactions);  
  const [currentId, setCurrentId] = useState(0);
  let querySort = query.get('sort') ? query.get('sort') : 'createdAt,asc';
  querySort = querySort.split(",");  
  const [sort, setSort] = useState({ sort: querySort[0], order: querySort[1] });

  useEffect(() => {
    if(page) {
      dispatch(getTransactions(page, sort, limit, user._id));
    }
  }, [sort, page, dispatch, navigate]);

  const paginationClick = (index) => {
    if(page === index + 1 ) return;
    setPage(index + 1);
    dispatch(getTransactions(index + 1, sort, limit, user?._id));
  };

	const handleSortBy = (sortVal, orderVal) => {
    const order = orderVal === 'asc' ? 'desc' : 'asc';
    
    setSort((prevState) => ({
      ...prevState,
      sort: sortVal,
      order: order
    }));    
    navigate(`/transactions/?page=${page}&sort=${sortVal},${order}`);
	};

  const hideCreateHandler = () => {
    setTransactionModal(false);
    setCurrentId(0);
    dispatch({ type: ERROR, payload: [] });   
  };


  return (
  <>
    {transactionModal && 
      <TransactionForm 
        onClose={hideCreateHandler} 
        setTransactionModal={(transactionModal) => setTransactionModal(transactionModal)} 
        currentId={currentId} 
        setCurrentId={(currentId) => setCurrentId(currentId)}
        setPage={(page) => setPage(page)}
        page={page}
        pageItems={transactions.length}
        sort={sort}
        setSort={(sort) => setPage(sort)}     
        limit={limit}          
      />
    }  
    {isLoading ? <ProjectsLoader /> : (
      <div className='projectDetails'>
        <div className='projectDetails__inner'>
          <div className="totalInfo" title="Total amount of transactions">
            <span className='label'>TOTAL AMOUNT</span>
            <span className='hours'>{formatter.format(totalAmount)}</span>
          </div>                           
        </div>
      </div>
    )}        
    {isLoading ? <ProjectsLoader /> : (
      <>
      {!transactions.length ? <div className='panel'><h4 className='noData'>No transactions</h4></div> : (
      <div className='panel'>  
        <table className={`items-list`}>
          <thead>
            <tr>
              <th><div className={`sortBy ${sort.sort === 'description' ? 'active-'+sort.order : ''}`} onClick={() => handleSortBy('description', sort.order)}>Description</div></th>
              <th><div className={`sortBy ${sort.sort === 'amount' ? 'active-'+sort.order : ''}`} onClick={() => handleSortBy('amount', sort.order)}>Amount</div></th>
              <th className='hideMobile'><div className={`sortBy ${sort.sort === 'createdAt' ? 'active-'+sort.order : ''}`} onClick={() => handleSortBy('createdAt', sort.order)}>Date</div></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
              {transactions?.map((transaction) => (                
                <Transaction 
                  key={transaction._id} 
                  className='item' 
                  transaction={transaction}
                  setCurrentId={(currentId) => setCurrentId(currentId)} 
                  currentId={currentId}
                  setTransactionModal={(transactionModal) => setTransactionModal(transactionModal)} 
                  setPage={(page) => setPage(page)}
                  page={page}
                  pageItems={transactions.length}
                  sort={sort}
                  setSort={(sort) => setPage(sort)}     
                  limit={limit}             
                />
              ))}  
          </tbody>     
        </table>
        <Pagination
          setPage={(page) => setPage(page)}
          page={page}
          limit={limit}
          total={transactions.length}
          pageItems={transactions.length}
          sort={sort}
          setSort={transactions.setSort}
          numberOfPages={numberOfPages}
          paginationClick={paginationClick}
        /> 
      </div>
      )}
      </>
    )}    
  </>
  )
}

export default Transactions