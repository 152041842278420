export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const FETCH_ALL_PROJECTS = 'FETCH_ALL_PROJECTS';
export const FETCH_PROJECT = 'FETCH_PROJECT';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const PROJECTS_DEFAULT = 'FETCH_BY_SEARCH';

export const CREATE_CLIENT = 'CREATE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const FETCH_ALL_CLIENTS = 'FETCH_ALL_CLIENTS';
export const FETCH_CLIENT = 'FETCH_CLIENT';

export const CREATE_TIMELOG = 'CREATE_TIMELOG';
export const UPDATE_TIMELOG = 'UPDATE_TIMELOG';
export const DELETE_TIMELOG = 'DELETE_TIMELOG';
export const FETCH_ALL_TIMELOGS = 'FETCH_ALL_TIMELOGS';
export const FETCH_TIMELOG = 'FETCH_TIMELOG';
export const UPDATE_TOTAL = 'UPDATE_TOTAL';
export const CLEAR_TIMELOGS = 'CLEAR_TIMELOGS';
export const CLEAR_TEMP_LOGS = 'CLEAR_TEMP_LOGS';

export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const FETCH_ALL_TRANSACTIONS = 'FETCH_ALL_TRANSACTIONS';
export const FETCH_TRANSACTION = 'FETCH_TRANSACTION';
export const FETCH_TIMELOG_IN_PROGRESS = 'FETCH_TIMELOG_IN_PROGRESS';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const ERROR = 'ERROR';

export const FLASH_MESSAGE = 'FLASH_MESSAGE';

export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';

export const UPDATE_USER_DEFAULTS = 'UPDATE_USER_DEFAULTS';
export const FETCH_USER_DEFAULTS = 'FETCH_USER_DEFAULTS';

export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';

export const START_LOADING_TIMELOG = 'START_LOADING';
export const END_LOADING_TIMELOG = 'END_LOADING';