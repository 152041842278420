import React from 'react'
import { RxHamburgerMenu } from 'react-icons/rx';
import { GrClose } from 'react-icons/gr';
import { useSelector } from 'react-redux';

const Header = ({setShowSidebar, showSidebar}) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <header className='mainHeader'>
        <span className='helloMessage' title={`Hello ${user?.firstName}`}>Hello {user?.firstName}</span>
        <span onClick={() => setShowSidebar(!showSidebar)} className='hamburgerIcon'>
            {showSidebar ? <GrClose /> : <RxHamburgerMenu/> }
        </span>
    </header>
  )
}

export default Header