import { END_LOADING, FETCH_BY_SEARCH, START_LOADING, ERROR, FETCH_CLIENT, FETCH_ALL_CLIENTS, UPDATE_CLIENT, DELETE_CLIENT, CREATE_CLIENT, REMOVE_ACCOUNT } from "../constants/actionTypes";

const clients = (state = { isLoading: true, clients: [], errors: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };    
      case FETCH_ALL_CLIENTS:
        return {
          ...state,
          clients: action.payload.data
        };
      case FETCH_BY_SEARCH:
        return { ...state, clients: action.payload.data };  
      case FETCH_CLIENT:
        return { ...state, client: action.payload.client };      
      case CREATE_CLIENT:
        return { 
          ...state, 
          clients: [action.payload.newClient, ...state.clients]
        };
      case UPDATE_CLIENT:
        return { ...state, clients: state.clients.map((client) => (client._id === action.payload._id ? action.payload : client)) };
      case DELETE_CLIENT:
        return { 
          ...state, 
          clients: state.clients.filter((client) => client._id !== action.payload),
        };              
      case ERROR:
          return { ...state, errors: action.payload, isLoading: false }; 
      case REMOVE_ACCOUNT:
          return { ...state, isLoading: true, clients: [], errors: [] };                     
      default:
        return state;
    }
  };
  
  export default clients;