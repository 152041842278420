import { END_LOADING, FETCH_BY_SEARCH, START_LOADING, ERROR, FETCH_ALL_TRANSACTIONS, FETCH_TRANSACTION, CREATE_TRANSACTION, UPDATE_TRANSACTION, DELETE_TRANSACTION, REMOVE_ACCOUNT } from "../constants/actionTypes";

const transactions = (state = { isLoading: true, transactions: [], transaction: [], numberOfPages: [], errors: [], totalAmount: 0 }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };    
      case FETCH_ALL_TRANSACTIONS:
        return {
          ...state,
          transactions: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,  
          totalAmount: action.payload.totalAmount        
        };
      case FETCH_BY_SEARCH:
        return { ...state, transactions: action.payload.data };  
      case FETCH_TRANSACTION:
        return { ...state, transaction: action.payload.transaction };      
      case CREATE_TRANSACTION:
        return { 
          ...state, 
          transactions: [action.payload.newTransaction, ...state.transactions],
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
          totalAmount: action.payload.totalAmount          
        };
      case UPDATE_TRANSACTION:
        return {
          ...state, 
          transactions: state.transactions.map((transaction) => (transaction._id === action.payload.updatedTransaction._id ? action.payload.updatedTransaction : transaction)), 
          totalAmount: action.payload.totalAmount         
        };
      case DELETE_TRANSACTION:
        return { 
          ...state, 
          transactions: state.transactions.filter((transaction) => transaction._id !== action.payload.deletedProject._id),
          totalAmount: action.payload.totalAmount
        };              
      case ERROR:
          return { ...state, errors: action.payload, isLoading: false };
      case REMOVE_ACCOUNT:
          return { ...state, isLoading: true, transactions: [], transaction: [], numberOfPages: [], errors: [], totalAmount: 0 };                     
      default:
        return state;
    }
  };
  
  export default transactions;