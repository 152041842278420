import React from 'react'
import TimeLogForm from '../../components/TimeLogs/TimeLogForm/TimeLogForm'
import TimeLogs from '../../components/TimeLogs/TimeLogs'

const TimerPage = () => {
  return (
    <>
        <TimeLogForm projectSelect={true} />
        <TimeLogs showProjectTitle={true} />
    </>
  )
}

export default TimerPage