import * as api from '../api';
import { END_LOADING, ERROR, FETCH_USER_DEFAULTS, FLASH_MESSAGE, START_LOADING, UPDATE_USER_DEFAULTS } from "../constants/actionTypes";

export const updateUserDefaults = (userDefaults) => async (dispatch) => {

    try {
        const { data } = await api.updateUserDefaults(userDefaults);

        dispatch({ type: UPDATE_USER_DEFAULTS, payload: data });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: 'Profile updated',
            state: 'success'  
        }});

        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
};

export const getUserDefaults = (user) => async (dispatch) => {
    //dispatch({ type: START_LOADING });

    try {
        const { data } = await api.fetchUserDefaults(user);

        dispatch({ type: FETCH_USER_DEFAULTS, payload: data });
        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
    //dispatch({ type: END_LOADING });    
};