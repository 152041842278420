import { combineReducers } from "redux";

import transactions from "./transactions";
import timeLogs from "./timeLogs";
import projects from "./projects";
import clients from "./clients";
import auth from "./auth";
import shared from "./shared";
import userDefaults from "./userDefaults";

export default combineReducers({
    transactions,
    timeLogs,
    projects,
    clients,
    auth,
    shared,
    userDefaults
});