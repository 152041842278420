import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link, useNavigate } from "react-router-dom";
import { signin, signup } from '../../actions/auth';
import { ERROR } from '../../constants/actionTypes';
import ComponentLoader from '../Loaders/ComponentLoader/ComponentLoader';
import TextInput from '../UI/Input/TextInput';

const initialState = { 
  firstName: {
    value: '',
    valid: false
  },
  lastName: {
    value: '',
    valid: false
  },
  email: {
    value: '',
    valid: false
  },
  password: {
    value: '',
    valid: false
  },
  confirmPassword: {
    value: '',
    valid: false
  },         
};

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState(initialState);
  const [isSignup, setIsSignup] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const { authData, isLoading, errors } = useSelector((state) => state.auth);
  //const [showPassword, setShowPassword] = useState(false);
  //const handleShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = (e) => {
      e.preventDefault();

      const filtered = Object.keys(formData)
      .filter((field) => formData[field].valid !== true);

      if (isSignup) {

        if(filtered.length) { return; }

        dispatch(signup(formData, navigate));
      } else {
        dispatch(signin(formData, navigate));
      }  
      
  };

  const handleChange = (e) => {
    !isValidForm && setIsValidForm(true);
    setFormData(prevState => { 
      return { ...prevState, [e.target.name] : { value: e.target.value, valid: e.isValid } }
    });
  }

  const switchMode = () => {
      setFormData(initialState);
      setIsSignup((prevIsSignup) => !prevIsSignup);
      //setShowPassword(false);
      dispatch({ type: ERROR })
  }; 

  return (
    <>
      <div className='authForm'>
        {isLoading && <ComponentLoader />}  
        <div className='authForm__heading'>
          <h2>{isSignup ? 'Sign Up' : 'Sign In'}</h2>
        </div>
        <form onSubmit={handleSubmit}>          
          { isSignup && (
            <>                
              <TextInput
                type="text"
                id="name"
                name="firstName"
                placeholder="Name"
                validate="required"
                value={formData.firstName.value}
                label="Name"
                required
                events={{
                  onChange: data => handleChange(data)
                }}
              />
              <TextInput
                type="text"
                label="Last Name"
                id="lastname"
                name="lastName" 
                placeholder="Last Name"
                validate="required"
                value={formData.lastName.value}       
                required
                events={{
                  onChange: data => handleChange(data)
                }}
              />        
            </>
          )}
          <TextInput
            type="text"
            label="Email"
            id="email" 
            placeholder="Email" 
            name="email" 
            validate="email"
            value={formData.email.value}    
            required
            events={{
              onChange: data => handleChange(data)
            }}        
          />
          <TextInput
            id="password" 
            label="Password"
            placeholder="Password" 
            type="password"
            name="password"
            validate="password"
            value={formData.password.value}   
            required
            events={{
              onChange: data => handleChange(data)
            }}        
            />
          { isSignup && 
            <TextInput 
              id="confirmPassword" 
              label="Repeat Password"
              placeholder="Repeat Password" 
              name="confirmPassword"
              patternmatch={formData.password.value}
              validate="patternmatch"
              type="password"
              value={formData.confirmPassword.value}
              required
              events={{
                onChange: data => handleChange(data)
              }}          
            />
          }    
          <button type='submit' className='button primary' disabled={!isValidForm}>{ isSignup ? 'Sign Up' : 'Sign In' }</button>
          {errors && <div className='form__global-errors'>{errors}</div>}
          <span onClick={switchMode} className='switchMode'>
            { isSignup ? 'Already have an account? Sign in' : "Don't have an account? Sign Up" }
          </span>   
          {!isSignup &&
          <span className='switchMode' element="link" to="/password-reset">
            <Link className='forgotPassword' to="/password-reset">Forgot Password?</Link>            
          </span>
          }
        </form>
      </div>
    </>
  )
};

export default SignUp;