import axios from 'axios';

//const API = axios.create({ baseURL: 'http://localhost:5000' });
const API = axios.create({ baseURL: 'https://phpstack-417580-4155706.cloudwaysapps.com/' });

API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
  
    return req;
});

export const fetchProject = (id) => API.get(`/projects/${id}`);
export const fetchProjects = (page, sortBy, limit, user) => API.get(`/projects?page=${page}&sort=${sortBy.sort},${sortBy.order}&limit=${limit}&user=${user}`);
export const fetchProjectsBySearch = (searchQuery) => API.get(`/projects/search?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`);
export const createProject = (newProject) => API.post(`/projects?&sort=${newProject.sort.sort},${newProject.sort.order}`, newProject);
export const updateProject = (id, project) => API.patch(`/projects/${id}`, project);
export const deleteProject = (project) => API.delete(`/projects/${project._id}`, { data: project });

export const fetchClients = (user) => API.get(`/clients?&user=${user}`);
export const createClient = (newClient) => API.post(`/clients`, newClient);
export const updateClient = (id, client) => API.patch(`/clients/${id}`, client);
export const deleteClient = (client) => API.delete(`/clients/${client._id}`, { data: client });

export const fetchTimeLogs = (page, sortBy, limit, projectId, user) => API.get(`/timelogs?page=${page}&sort=${sortBy.sort},${sortBy.order}&limit=${limit}&user=${user}&projectId=${projectId}`);
export const fetchTimeLogInProgress = (user) => API.get(`/timelogs/${user._id}`);
export const createTimeLog = (newTimelog) => API.post(`/timelogs?&sort=${newTimelog.sort.sort},${newTimelog.sort.order}`, newTimelog);
export const updateTimeLog = (id, timelog) => API.patch(`/timelogs/${id}`, timelog);
export const updateProjectTimeLogsPaidStatus = (timelogs) => API.patch(`/timelogs?projectId${timelogs.currentId}`, timelogs);
export const deleteTimeLog = (timelog) => API.delete(`/timelogs/${timelog._id}`, { data: timelog });
export const deleteTempTimeLog = (timelog) => API.delete(`/timelogs/temp/${timelog._id}`, { data: timelog });

export const fetchTransactions = (page, sortBy, limit, user) => API.get(`/transactions?page=${page}&sort=${sortBy.sort},${sortBy.order}&limit=${limit}&user=${user}`);
export const createTransaction = (newTransaction) => API.post(`/transactions`, newTransaction);
export const updateTransaction = (id, transaction) => API.patch(`/transactions/${id}`, transaction);
export const deleteTransaction = (transaction) => API.delete(`/transactions/${transaction._id}`, { data: transaction });

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const confirmEmail = (data) => API.patch(`/user/confirm-email?token=${data.token}`);
// export const passwordResetLink = (formData) => API.patch(`/user/password-reset`, formData);
export const passwordReset = (formData) => API.patch(`/user/password-reset`, formData);

export const updateUser = (user) => API.patch(`/user/${user.userId}`, user);
export const removeAccount = (user) => API.delete(`/user/${user._id}`, user);

export const fetchUserDefaults = (user) => API.get(`/userdefaults?userId=${user._id}`);
export const updateUserDefaults = (data) => API.patch(`/userdefaults/`, { defaults: data });