import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { removeAccount } from '../../actions/users';
import { ERROR } from '../../constants/actionTypes';
import ComponentLoader from '../Loaders/ComponentLoader/ComponentLoader';
import Modal from '../UI/Modal/Modal';

const RemoveAccount = ({user}) => {
    const { isLoading } = useSelector((state) => state.auth);    
    const dispatch = useDispatch();    
    const [openConfirm, setOpenConfirm] = useState();

    const onClose = (e) => {
        e.preventDefault();        
        setOpenConfirm(false);
        dispatch({ type: ERROR, data: [] });
    };

    const handleDelete = (e) => {
        e.preventDefault();        
        dispatch(removeAccount(user));
    };

    return (
        <>
            {openConfirm && 
                <Modal onClose={onClose} title="Remove account">
                    {isLoading && <ComponentLoader />}
                    <div className="modal__content">
                        <p>Are you sure you want to remove account?</p>
                        <div className="modal__warning">
                            <p><b>This action cannot be reversed</b></p>
                            <p>Deleting the account will cause removing all Time Entries, Clients and Transactions it has been added to.</p>
                        </div>            
                    </div>
                    <div className="modal__footer footer__multi-button">
                        <button className='button danger full-width' onClick={handleDelete}>Delete</button>
                        <button className='button full-width' onClick={onClose}>Cancel</button>
                    </div>
                </Modal>
            }             
            <div className='closeAccount'>
                <button className="button danger outline" onClick={() => setOpenConfirm(true)}>Close Account</button>
            </div>
        </>
    )
}

export default RemoveAccount