import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getTimeLogs } from '../../actions/timeLogs';
import { useHooks } from '../../utils/hooks';
import ProjectsLoader from '../Loaders/ProjectLoader/ProjectsLoader';
import Pagination from '../Pagination/Pagination';
import TimeLog from './TimeLog/TimeLog'

const TimeLogs = ({projectId, showProjectTitle}) => {
    const { dispatch, navigate, page, setPage, query, limit, user } = useHooks();  
    const { timeLogs, numberOfPages, isLoading } = useSelector((state) => state.timeLogs);  
    let querySort = query.get('sort') ? query.get('sort') : 'date,desc';
    querySort = querySort.split(",");  
    const [sort, setSort] = useState({ sort: querySort[0], order: querySort[1] });

    useEffect(() => {
        if (page) {
            dispatch(getTimeLogs(page, sort, limit, projectId, user._id));
        }
    }, [sort, page, dispatch, projectId, page]);

	const handleSortBy = (sortVal, orderVal) => {
        const order = orderVal === 'asc' ? 'desc' : 'asc';
        
        setSort((prevState) => ({
          ...prevState,
          sort: sortVal,
          order: order
        }));    
        navigate(`?page=${page}&sort=${sortVal},${order}`);
    };

    const paginationClick = (index) => {
        if(page === index + 1 ) return;
        setPage(index + 1);
        dispatch(getTimeLogs(index + 1, sort, limit, projectId, user?._id));
    };

    return (
        <>
        {isLoading ? <ProjectsLoader /> : (
            <>
            {!timeLogs.length ? <div className='panel'><h4 className='noData'>No logs</h4></div> : (        
                <div className='panel'>
                    <table className={`items-list`}>
                        <thead>
                            <tr>
                                {showProjectTitle && <th className='hideMobile'><div>Project Name</div></th> }
                                <th><div className={`sortBy ${sort.sort === 'taskDescription' ? 'active-'+sort.order : ''}`} onClick={() => handleSortBy('taskDescription', sort.order)}>Description</div></th>
                                <th className='hideTablet hideMobile'><div className={`sortBy ${sort.sort === 'date' ? 'active-'+sort.order : ''}`} onClick={() => handleSortBy('date', sort.order)}>Date</div></th>
                                <th className='hideMobile'><div className={`sortBy ${sort.sort === 'totalTime' ? 'active-'+sort.order : ''}`} onClick={() => handleSortBy('totalTime', sort.order)}>Time</div></th>
                                <th className='hideMobile'><div className={`sortBy ${sort.sort === 'totalAmount' ? 'active-'+sort.order : ''}`} onClick={() => handleSortBy('totalAmount', sort.order)}>Amount</div></th>
                                <th><div className={`sortBy ${sort.sort === 'paidStatus' ? 'active-'+sort.order : ''}`} onClick={() => handleSortBy('paidStatus', sort.order)}>Paid</div></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {timeLogs?.map((timeLog) => (
                            <TimeLog 
                                key={timeLog._id} 
                                timeLog={timeLog}
                                setPage={(page) => setPage(page)}
                                page={page}
                                limit={limit}
                                pageItems={timeLogs.length}
                                sort={sort}
                                setSort={timeLogs.setSort}                    
                                numberOfPages={numberOfPages} 
                                showProjectTitle={showProjectTitle}
                                projectId={projectId}
                            />
                        ))}
                        </tbody>
                    </table>   
                    <Pagination
                        setPage={(page) => setPage(page)}
                        page={page}
                        limit={limit}
                        pageItems={timeLogs.length}
                        sort={sort}
                        setSort={timeLogs.setSort}                    
                        numberOfPages={numberOfPages}
                        paginationClick={paginationClick}                    
                    />                      
                </div>
            )}
            </>
        )}
        </>
    )
}

export default TimeLogs