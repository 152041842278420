import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai';
import Clients from '../../components/Clients/Clients';

const ClientsPage = () => {
  const [clientModal, setClientModal] = useState(false);

  const showCreateHandler = () => {
    setClientModal(true);
  };

  return (
    <>
    <div className="pageHeading">
      <div className="titleContainer">
        <h2>Clients</h2>
      </div>
      <div className="asideContainer">
        <button onClick={showCreateHandler} type="button" className='button primary'>
          <AiOutlinePlus className='icon' /><span>New client</span>
        </button>        
      </div>
    </div>
    <Clients
      clientModal={clientModal}
      setClientModal={(clientModal) => setClientModal(clientModal)}  
    />
    </>
  )
}

export default ClientsPage