import React, { useEffect, useState } from 'react'
import styles from './Timer.module.scss';

import { FaPlayCircle, FaStopCircle } from 'react-icons/fa';
import moment from 'moment';

const Timer = ({setTimer, timer, setOpenDatepicker, setTimerRunning, timerRunning, handleSubmit, timeLogData}) => {
    const [time, setTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [start, setStart] = useState(timeLogData.start.timeDate);
    console.log(timeLogData?.projectId)
    useEffect(() => {
      if(timerRunning) {
        setIsRunning(true);
        setTime(timer);
      } else {
        handleReset();
      }
    }, [timerRunning]);

    useEffect(() => {
      //isRunning && time === 0 && setStart(moment().format());
      //isRunning && time > 0 && setStart(timeLogData.start.timeDate);
      //console.log(start, time, timeLogData.start.timeDate, timer)
      let interval = null; 
   
      if (isRunning) {  

        interval = setInterval(() => {
          let duration = moment.duration(moment().diff(moment(start)));
          duration = Math.floor(duration.asSeconds());   
          setTime(duration); 
          setTimer(duration);         
        }, 1000);
        //minuteReached && setTimer(time);        
      } else if (!isRunning && time !== 0) {
        clearInterval(interval);
        setTimer(0);
      }
      return () => clearInterval(interval);
    }, [time, isRunning, start]);

    const handleStartStop = (e) => {
      e.preventDefault();
      setStart(moment().format());

      if(!timeLogData.projectId) return;

      if(isRunning) {
        handleReset();
      }

      handleSubmit(e);

      setIsRunning(!isRunning);
      setTimerRunning(!isRunning);
    };
  
    const handleReset = () => {
      setTime(0);
      setTimer(0);
      setIsRunning(false);
      setTimerRunning(false);
      setStart(moment().format());
    };
  
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return (
        <div className={`${styles.timer} ${isRunning ? styles.running : ''}`}>
            <div className={`${styles.timerLog}`} onClick={() => setOpenDatepicker(true)}>{hours}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</div>
            <button onClick={handleStartStop} title={!timeLogData.projectId ? "Please select the project first" : ''} type="submit" className={`submitTimeLog ${isRunning ? 'isRunning' : ''}`}>{isRunning ? <FaStopCircle title="Cancel" /> : <FaPlayCircle /> }</button>
        </div>
    )
}

export default Timer