import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import FlashMessage from '../../components/FlashMessage/FlashMessage';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useComponentVisible } from '../../utils/utils';

import styles from './RootLayout.module.scss';

const RootLayout = () => {
  const { user } = useSelector((state) => state.auth);
  const [showSidebar, setShowSidebar] = useState(null);

  return (
    <>
      {/* {!user && isLoading && <Loading />} */}
      <div className={`${styles.pageWrapper} ${user ? styles.loggedIn : ''}`} >
          {user && 
          <>
            <Header 
              showSidebar={showSidebar}
              user={user}
              setShowSidebar={(showSidebar) => setShowSidebar(showSidebar)}
            /> 
            <Sidebar 
              showSidebar={showSidebar} 
              setShowSidebar={(showSidebar) => setShowSidebar(showSidebar)}
            />
            </>
            }
          <div className={styles.rightPane}>
            <Outlet />
          </div>
      </div>
      <FlashMessage />
    </>
  )
}

export default RootLayout