import { AUTH, END_LOADING, ERROR, START_LOADING } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const signin = (formData, navigate) => async (dispatch) => {
  dispatch({ type: START_LOADING });
  try {    

    const { data } = await api.signIn(formData);

    if(data.user.isConfirmed) {
      dispatch({ type: AUTH, data });
      navigate('/');
      dispatch({ type: ERROR, data: [] });
    } else {
      dispatch({ type: ERROR, data: "Please confirm email address." });
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
  dispatch({ type: END_LOADING });
};

export const signup = (formData, navigate) => async (dispatch) => {
  dispatch({ type: START_LOADING });

  try {
    const { data } = await api.signUp(formData);

    if(data.user.isConfirmed) {
      dispatch({ type: AUTH, data });
      navigate('/');
    } else {
      //dispatch({ type: ERROR, data: "Please confirm email address." });
      navigate('/confirm-email');
    }
    dispatch({ type: ERROR, data: [] });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
  dispatch({ type: END_LOADING });  
};

export const confirmEmail = (confirmData) => async (dispatch) => {
  dispatch({ type: START_LOADING });

  try {
    const data = await api.confirmEmail(confirmData);
    dispatch({ type: ERROR, data: [] });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
  dispatch({ type: END_LOADING });  
};

export const passwordReset = (formData) => async (dispatch) => {
  dispatch({ type: START_LOADING });

  try {
    const data = await api.passwordReset(formData);
    dispatch({ type: ERROR, data: [] });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
  dispatch({ type: END_LOADING });  
};

