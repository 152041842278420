import React, { useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai';
import Transactions from '../../components/Transactions/Transactions';
import './TransactionsPage.scss';

const ClientsPage = () => {
  const [transactionModal, setTransactionModal] = useState(false);

  const showCreateHandler = (e) => {
    e.preventDefault();
    setTransactionModal(true);
  };

  return (
    <>
      <div className="pageHeading">
        <div className="titleContainer">
          <h2>Transactions</h2>
        </div>
        <div className="asideContainer">
          <button type="button" className="button primary" onClick={showCreateHandler}>
            <AiOutlinePlus className='icon' /><span>New transaction</span>          
          </button>        
        </div>
      </div>
      <Transactions 
        transactionModal={transactionModal}
        setTransactionModal={(transactionModal) => setTransactionModal(transactionModal)} 
      />
    </>
  )
}

export default ClientsPage