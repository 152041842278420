import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createClient } from '../../../actions/clients';
import ComponentLoader from '../../Loaders/ComponentLoader/ComponentLoader';

import TextInput from '../../UI/Input/TextInput'
import Modal from '../../UI/Modal/Modal'

const initialState = { 
  name: ''        
};

const ClientForm = ({ onClose, setProjectModal }) => {
  const [projectData, setProjectData] = useState(initialState);  
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState(false);
  const { errors, isLoading } = useSelector((state) => state.projects); 
  const [showErrors, setShowErrors] = useState(false);    
  const { user } = useSelector((state) => state.auth);  

  useEffect(() => {
    if(showErrors) {
      setTimeout( () => setShowErrors(false), 3000);
    }
  }, [showErrors]);

  useEffect(() => {
    errors?.response && setShowErrors(true);
    !errors?.response && successMessage && clear();
  }, [errors]);

  const clear = () => {
    setProjectData(initialState);
    setProjectModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
      dispatch(createClient({ ...projectData, creator: user?._id }));
      setSuccessMessage(true);
  }; 
  
  const handleChange = (e) => {
    setProjectData(prevState => { 
      return { ...prevState, [e.target.name] : e.target.value }
    });
  }  

  return (
    <>
    <Modal onClose={onClose} title="Add new client">
      <form onSubmit={handleSubmit}>
        {isLoading && <ComponentLoader />}  
        <TextInput
          type="text"
          id="name"
          name="name"
          placeholder="Name"
          validate="required"
          value={projectData.name}
          required
          events={{
            onChange: data => handleChange(data)
          }}
        />                   
        <button type='submit' className='button primary full-width'>Add client</button>
        {errors?.message && showErrors && <div className='form__global-errors'>{errors.response.data.message}</div>}        
      </form>
      
    </Modal>
    </>
  )
}

export default ClientForm