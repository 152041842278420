import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdOutlinePaid, MdPaid } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteProject, getProjects } from '../../../actions/projects';
import { updateProjectTimeLogsPaidStatus } from '../../../actions/timeLogs';
import { FLASH_MESSAGE } from '../../../constants/actionTypes';
import { timeFormat, useComponentVisible } from '../../../utils/utils';
import Modal from '../../UI/Modal/Modal';

import styles from './Project.module.scss';

const Project = ({ project, setCurrentId, currentId, setPage, page, pageItems, sort, limit, setProjectModal }) => {
  let { numberOfPages } = useSelector((state) => state.projects);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const isPaid = project.totalHours === project.paidHours;
  page = (pageItems === 1 && numberOfPages > 1) ? page-1 : page;  
  //numberOfPages = (pageItems === 1 && numberOfPages > 1) ? numberOfPages-1 : numberOfPages;  

  const { 
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  useEffect(() => {
    setIsComponentVisible(false);
  }, [openConfirm]);

  useEffect(() => {
    if(deleteConfirm) {
      setPage(page);
      if(page) {
        dispatch(getProjects(page, sort, limit, user._id));
        navigate(`?page=${page}&sort=${sort.sort},${sort.order}`);
      } 
    }

  }, [deleteConfirm, dispatch, page, currentId]);

  const openProject = (e) => {
    navigate(`/projects/${project._id}`);
  };  

  const handleEdit = (e) => {
    e.stopPropagation();
    setProjectModal(true);
    setCurrentId(project._id);
    setIsComponentVisible(false);
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    setIsComponentVisible(false);

    if(openConfirm) {
      dispatch(deleteProject({...project, currentUser: user?._id}));  
      setDeleteConfirm(true);
    }

    setOpenConfirm(!openConfirm);    
  };

  const onClose = () => {
    setOpenConfirm(false);
    setCurrentId(0);
  }

  const handlePaidStatus = async (e) => {
    e.stopPropagation();
    if(project.totalHours > 0) {
      dispatch(updateProjectTimeLogsPaidStatus({ projectId: project._id, paidStatus: !isPaid, totalAmount: project.totalAmount, totalHours: project.totalHours, userId: user._id  }));          
    }
  };  

  return (<>
      {openConfirm && 
        <Modal onClose={onClose} title="Delete Project">
          <div className="modal__content">
            <p>Are you sure you want to delete <b>{project.title}</b>?</p>
            <div className="modal__warning">
                <p><b>This action cannot be reversed</b></p>
                <p>Deleting the Project will cause it to be removed from all Time Entries it has been added to.</p>
            </div>            
          </div>
          <div className="modal__footer footer__multi-button">
            <button className='button danger full-width' onClick={handleDelete}>Delete</button>
            <button className='button full-width'onClick={onClose}>Cancel</button>
          </div>
        </Modal>
      }     
      <tr className={`${styles.item} item hoverShowOptions`} onClick={openProject}>
        <td className='projectName'>{project.title}</td>        
        <td className='projectName hideMobile'>{project?.client?.name || "No client"}</td>        
        <td className='hideTablet'>{moment(project.modifiedAt).format('MMM DD YYYY, hh:mm A')}</td>        
        <td className='hideTablet'>{timeFormat(project.totalHours)} h</td>
        <td className='hideTablet'>{timeFormat(project.paidHours)} h</td>
        <td><button title={project.totalHours > 0 ? 'Mark as paid (all time logs inside the project will be marked as paid)' : 'No timelogs'} onClick={handlePaidStatus} disabled={!project.totalHours > 0} className={`paidStatus ${isPaid && project.totalHours > 0 ? 'paid' : 'notpaid'}`}>{isPaid && project.totalHours > 0 ? <MdPaid />  : <MdOutlinePaid /> }</button></td>               
        <td>
          <div className='moreOptions' ref={ref} onClick={(e) => e.stopPropagation()}>            
            { isComponentVisible &&
            <div className='ctas'>
              <ul>
                <li><span className='edit' onClick={handleEdit}>Edit</span></li>
                <li><span className='delete' onClick={() => setOpenConfirm(true)}>Delete</span></li>
              </ul>
            </div>
            }   
            <span className={`openOptions ${styles.openOptions} ${isComponentVisible ? styles.open : ''}`} onClick={(e) => setIsComponentVisible(true)}>
              <BsThreeDotsVertical className='icon' />
            </span>         
          </div>          
        </td>               
      </tr>
      </>
  )
}

export default Project