import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { passwordReset } from '../../../actions/auth';
import ComponentLoader from '../../Loaders/ComponentLoader/ComponentLoader';
import TextInput from '../../UI/Input/TextInput';

const initialState = { 
    email: {
      value: '',
      valid: false
    },
    password: {
      value: '',
      valid: false
    },
    confirmPassword: {
      value: '',
      valid: false
    },
    token: {
        value: null,
        valid: false
    },            
  };

const PasswordReset = () => {
    const dispatch = useDispatch();    
    const [hasToken, setHasToken] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [isValidForm, setIsValidForm] = useState(false);
    const [success, setSuccess] = useState(false);
    const { errors, isLoading } = useSelector((state) => state.auth);  
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");          

    useEffect(() => {
        if(token) {
            setHasToken(true);
            setFormData(prevState => { 
                return { ...prevState, token : { value: token, valid: true } }
            });
        }
    }, []);
    
    useEffect(() => {
        if(!errors?.length && isValidForm) setSuccess(true);
    }, [errors, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(passwordReset(formData));
    };

    const handleChange = (e) => {
        !isValidForm && setIsValidForm(true);
        setFormData(prevState => { 
          return { ...prevState, [e.target.name] : { value: e.target.value, valid: e.isValid } }
        });
    }

    return (
        <div className={`authForm passwordReset`}>
            {isLoading && <ComponentLoader />}
            <div className='authForm__heading'>
                <h2>Password Reset</h2>
            </div>
            {success && !errors?.length ? (
                <div>
                    { !hasToken ? ( 
                        <>
                            <p>You will receive an email to your email address with password reset link. Please click on the link in order to reset your password. </p>
                            <p>In case you don't see email please check the spam folder in your email client.</p>
                        </>
                    ) : (
                        <>
                            <p>Congratulations! Your password has been changed successfully</p>
                            <p>You can login with new password.</p>
                        </>
                    )}
                    <Link element={Link} className="button primary" to='/auth'>Return to Login</Link>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>          
                { !hasToken && (
                    <>
                        <TextInput
                            type="text"
                            label="Email"
                            id="email" 
                            placeholder="Email" 
                            name="email" 
                            validate="email"
                            value={formData.email.value}    
                            required
                            events={{
                                onChange: data => handleChange(data)
                            }}        
                        />
                    </>
                )}
                { hasToken && (
                    <>
                        <TextInput
                            id="password" 
                            label="Password"
                            placeholder="Password" 
                            type="password"
                            name="password"
                            validate="password"
                            value={formData.password.value}   
                            required
                            events={{
                                onChange: data => handleChange(data)
                            }}       
                        />
                        <TextInput 
                            id="confirmPassword" 
                            label="Repeat Password"
                            placeholder="Repeat Password" 
                            name="confirmPassword"
                            patternmatch={formData.password.value}
                            validate="patternmatch"
                            type="password"
                            value={formData.confirmPassword.value}
                            required
                            events={{
                                onChange: data => handleChange(data)
                            }}          
                        />                    
                    </>
                )}            
                <button type='submit' className='button primary' disabled={!isValidForm}>{ !hasToken ? 'Send Reset Link' : 'Reset Password' }</button>
                <Link element={Link} className="button full-width" to='/auth'>Return to Login</Link>
                {errors && <div className='form__global-errors'>{errors}</div>}            
                </form>  
            )}                     
        </div>
    )
}

export default PasswordReset