import * as api from '../api';
import { CREATE_TRANSACTION, DELETE_TRANSACTION, END_LOADING, ERROR, FETCH_ALL_TRANSACTIONS, FLASH_MESSAGE, START_LOADING, UPDATE_TRANSACTION } from "../constants/actionTypes";

export const getTransactions = (page, sort, limit, user) => async (dispatch) => {
    dispatch({ type: START_LOADING });
    try {
        const { data } = await api.fetchTransactions(page, sort, limit, user);

        dispatch({ type: FETCH_ALL_TRANSACTIONS, payload: data });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
    dispatch({ type: END_LOADING });    
};

export const createTransaction = (transaction) => async (dispatch) => {
    dispatch({ type: START_LOADING });    
    try {
        const { data } = await api.createTransaction(transaction);

        dispatch({ type: CREATE_TRANSACTION, payload: data });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: 'Transaction added successfully',
            state: 'success'  
        }});  

        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
    dispatch({ type: END_LOADING });    
};

export const updateTransaction = (id, transaction) => async (dispatch) => {
    //dispatch({ type: START_LOADING });    
    try {
        const { data } = await api.updateTransaction(id, transaction);
        dispatch({ type: UPDATE_TRANSACTION, payload: data });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: 'Transaction edited successfully',
            state: 'success'  
        }});  

        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
    //dispatch({ type: END_LOADING });     
};

export const deleteTransaction = (transaction) => async (dispatch) => {
    dispatch({ type: START_LOADING });    
    try {
        const { data } = await api.deleteTransaction(transaction);

        dispatch({ type: DELETE_TRANSACTION, payload: data });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: 'Transaction successfully removed',
            state: 'success'  
        }});          
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
    dispatch({ type: END_LOADING });     
};