import React, { useState } from 'react'
import Projects from '../../components/Projects/Projects'
import './ProjectsPage.scss';

import { AiOutlinePlus } from 'react-icons/ai';

const ProjectsPage = () => {
  const [projectModal, setProjectModal] = useState(false);

  const showCreateHandler = (e) => {
    e.preventDefault();
    setProjectModal(true);
  };

  return (
    <>
    <div className="pageHeading">
      <div className="titleContainer">
        <h2>Projects</h2>
      </div>
      <div className="asideContainer">
        <button onClick={showCreateHandler} type="button" className='button primary'>
          <AiOutlinePlus className='icon' /><span>New project</span>
        </button>        
      </div>
    </div>
    <Projects 
      projectModal={projectModal}
      setProjectModal={(projectModal) => setProjectModal(projectModal)} 
    />
    </>
  )
}

export default ProjectsPage