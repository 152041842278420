import React from 'react'
import { FaSpinner } from 'react-icons/fa';

import styles from './ComponentLoader.module.scss';

const ComponentLoader = () => {
  return (
    <div className={styles.loading}><span className={styles.spinner}><FaSpinner /></span></div>
  )
}

export default ComponentLoader