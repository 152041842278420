import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../actions/users';
import ComponentLoader from '../../Loaders/ComponentLoader/ComponentLoader'
import TextInput from '../../UI/Input/TextInput';
import Modal from '../../UI/Modal/Modal'

const initialState = { 
  userId: '',
  password: '',
  newPassword: '',        
};

const PasswordChangeForm = ({setPasswordModal, passwordModal, user, onClose}) => {
    const dispatch = useDispatch();    
    const [showErrors, setShowErrors] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);  
    const { isLoading, errors } = useSelector((state) => state.auth);
    const [formData, setFormData] = useState({...initialState, userId: user._id});

    useEffect(() => {

      errors && setShowErrors(true);
      !errors && successMessage && clear();
  
    }, [errors, successMessage]);

    const clear = () => {
      setFormData(initialState);
      setPasswordModal(false);
    };

    const handleChange = (e) => {
      setFormData(prevState => { 
        return { ...prevState, [e.target.name] : e.target.value }
      });
    }

    const handleSubmit = async (e) => {
      e.preventDefault();

      dispatch(updateUser(formData));
      setSuccessMessage(true);
  };    

    return (
      <>
      <Modal onClose={onClose} title="Change password">
        <form onSubmit={handleSubmit}>
          {isLoading && <ComponentLoader />} 
          <TextInput
              id="password" 
              label="Current Password"
              placeholder="" 
              type="password"
              name="password"
              validate="password"
              value={formData.password}   
              required
              events={{
                onChange: data => handleChange(data)
              }}        
          />    
          <TextInput
              id="newPassword" 
              label="New Secure Password"
              placeholder="" 
              type="password"
              name="newPassword"
              validate="password"
              value={formData.newPassword}   
              required
              events={{
                onChange: data => handleChange(data)
              }}        
          />                                     
          <button type='submit' className='button primary full-width'>Save</button>    
          {errors && showErrors && <div className='form__global-errors'>{errors}</div>}
        </form>
        
      </Modal>
      </>
    )
}

export default PasswordChangeForm