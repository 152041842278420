import moment from "moment";
import { useEffect, useRef, useState } from "react";

export function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref, isComponentVisible, setIsComponentVisible };
}

export function timeFormat(data) {
    const time = moment.duration(data, 's');
    const hours = moment.duration(time?._milliseconds).asHours();

    return Math.floor(hours)+':'+time._data.minutes.toString().padStart(2, '0')+':'+time._data.seconds.toString().padStart(2, '0');
};

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


export function calcTotalTime (dateFrom, dateTo) {
    const duration = moment.duration(moment(dateTo).diff(moment(dateFrom)));
    let seconds = duration.asSeconds();
    return seconds = (seconds < 0) ? 86400-Math.abs(seconds) : seconds;       
};