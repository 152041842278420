import * as api from '../api';
import { CREATE_PROJECT, DELETE_PROJECT, DELETE_TIMELOG, END_LOADING, ERROR, FETCH_ALL_PROJECTS, FETCH_BY_SEARCH, FETCH_PROJECT, FLASH_MESSAGE, START_LOADING, UPDATE_PROJECT, UPDATE_TIMELOG } from '../constants/actionTypes';

export const getProject = (id) => async (dispatch) => {
    try {
        //dispatch({ type: START_LOADING });

        const { data } = await api.fetchProject(id);

        dispatch({ type: FETCH_PROJECT, payload: { project: data } });
        //dispatch({ type: END_LOADING });
        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
};

export const getProjects = (page, sort, limit, user) => async (dispatch) => {
    dispatch({ type: START_LOADING });
    try {
        const { data } = await api.fetchProjects(page, sort, limit, user);

        dispatch({ type: FETCH_ALL_PROJECTS, payload: data });
        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
    dispatch({ type: END_LOADING });    
};

export const getProjectsBySearch = (searchQuery) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data: { data } } = await api.fetchProjectsBySearch(searchQuery);

        dispatch({ type: FETCH_BY_SEARCH, payload: { data } });
        dispatch({ type: END_LOADING });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
};

export const createProject = (project, navigate) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.createProject(project);

        dispatch({ type: CREATE_PROJECT, payload: data });
        dispatch({ type: ERROR, payload: [] });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: 'Project added successfully',
            state: 'success'  
        }});  

        dispatch({ type: ERROR, payload: [] });
        dispatch({ type: END_LOADING });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
};

export const updateProject = (id, project) => async (dispatch) => {
    try {
        const { data } = await api.updateProject(id, project);

        dispatch({ type: UPDATE_PROJECT, payload: data });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: 'Project edited successfully',
            state: 'success'  
        }});  

        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
};

export const deleteProject = (project) => async (dispatch) => {
    try {
        const { data } = await api.deleteProject(project);

        dispatch({ type: DELETE_PROJECT, payload: data.deletedProject._id });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: 'Project successfully removed',
            state: 'success'  
        }});

        dispatch({ type: 'UPDATE_TOTAL', payload: data });
        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
};