import { END_LOADING, FETCH_BY_SEARCH, START_LOADING, ERROR, FETCH_ALL_TIMELOGS, CREATE_TIMELOG, FETCH_TIMELOG, DELETE_TIMELOG, UPDATE_TIMELOG, UPDATE_TOTAL, CLEAR_TIMELOGS, REMOVE_ACCOUNT, FETCH_TIMELOG_IN_PROGRESS, CLEAR_TEMP_LOGS } from "../constants/actionTypes";

const timeLogs = (state = { isLoading: true, lastTimelog: null, timeLogInProgress: null, totalInfo: {totalAmount: 0, paidAmount: 0, totalHours: 0, paidHours: 0 }, timeLogs: [], numberOfPages: [], errors: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case CLEAR_TIMELOGS:
        return { ...state, timeLogs: [] };            
      case FETCH_ALL_TIMELOGS:
        return {
          ...state,
          timeLogs: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
          totalInfo: action.payload.totalInfo,                 
        };
      case FETCH_BY_SEARCH:
        return { ...state, timeLogs: action.payload.data };  
      case FETCH_TIMELOG:
        return { ...state, timeLog: action.payload.timeLog };      
      case FETCH_TIMELOG_IN_PROGRESS:
        return { ...state, timeLogInProgress: action.payload.timeLogInProgress };              
      case CREATE_TIMELOG:
        return { 
          ...state, 
          timeLogs: action.payload.timerMode ? state.timeLogs : [action.payload.newTimeLog, ...state.timeLogs],
          lastTimelog: action.payload.timerMode ? action.payload.newTimeLog : state.lastTimelog,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
          //totalInfo: action.payload.totalInfo,                    
        };
      case UPDATE_TIMELOG:
        return {
          ...state, 
          timeLogs: action.payload.timerMode ? [action.payload.updatedTimelog, ...state.timeLogs] : state.timeLogs.map((timeLog) => (timeLog._id === action.payload.updatedTimelog._id ? action.payload.updatedTimelog: timeLog)),
          totalInfo: action.payload.totalInfo,
          timeLogInProgress: null,
          lastTimelog: null
        };
      case DELETE_TIMELOG:
        return { 
          ...state, 
          timeLogs: state.timeLogs.filter((timeLog) => timeLog._id !== action.payload),
          totalInfo: action.payload.totalInfo
        };
      case UPDATE_TOTAL:
        return { 
          ...state,           
          totalInfo: action.payload.totalInfo          
        };
      case CLEAR_TEMP_LOGS:
        return { 
          ...state,
          timeLogInProgress: null,
          lastTimelog: null
        };                                 
      case ERROR:
          return { ...state, errors: action.payload, isLoading: false };
      case REMOVE_ACCOUNT:
          return { isLoading: true, totalInfo: {totalAmount: 0, paidAmount: 0, totalHours: 0, paidHours: 0 }, timeLogs: [], numberOfPages: [], errors: [] };  
      default:
          return state;
    }
  };
  
  export default timeLogs;