import { END_LOADING, ERROR, FETCH_USER_DEFAULTS, REMOVE_ACCOUNT, START_LOADING, UPDATE_USER_DEFAULTS } from "../constants/actionTypes";

const userDefaults = (state = { isLoading: true, defaults: [], errors: [] }, action) => {
    switch (action.type) {
    case START_LOADING:
        return { ...state, isLoading: true };
    case END_LOADING:
        return { ...state, isLoading: false };
    case FETCH_USER_DEFAULTS:
        return {
            ...state,
            defaults: action.payload.userDefaults,     
        };        
    case UPDATE_USER_DEFAULTS:
        return {
            ...state, 
            defaults: state.defaults._id === action.payload.updatedUserDefaults._id ? action.payload.updatedUserDefaults : state.defaults
        };
    case ERROR:
        return { ...state, errors: action.payload, isLoading: false };
    case REMOVE_ACCOUNT:
        return { ...state, isLoading: true, defaults: [], errors: [] };                           
    default:
        return state;
    }
};
      
export default userDefaults;