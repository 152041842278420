import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

import './Pagination.scss';

const Pagination = ({ setPage, page, limit, sort, numberOfPages, paginationClick, pageItems }) => {
    const total = numberOfPages * limit;
	const totalPages = Math.ceil(total / limit);
    const location = useLocation();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(location.search);
    const pageParam = urlParams.get('page') ? Number(urlParams.get('page')) : page;

	return (
        <>
            {totalPages > 1 &&
                <div className="pagination">
                    <ul> 
                        { [...Array(totalPages)].map((val, index) => (
                            <li key={index}>
                                <Link
                                    onClick={() => paginationClick(index)}
                                    className={
                                        Number(page) === index + 1
                                            ? `button active`
                                            : `button`
                                    }                                    
                                    to={`?page=${index + 1}&sort=${sort.sort},${sort.order}`}
                                    >
                                    {index + 1}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </>
	);
};

export default Pagination;