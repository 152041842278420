import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getClients } from '../../../actions/clients';
import { getProject } from '../../../actions/projects';
import { ERROR } from '../../../constants/actionTypes';
import { formatter, timeFormat } from '../../../utils/utils';
import ProjectsLoader from '../../Loaders/ProjectLoader/ProjectsLoader';
import TimeLogs from '../../TimeLogs/TimeLogs';
import ProjectForm from '../ProjectForm/ProjectForm';
import TimeLogForm from '../../TimeLogs/TimeLogForm/TimeLogForm';

import { MdModeEdit } from 'react-icons/md';

const ProjectDetails = () => {
  const { project, isLoading } = useSelector((state) => state.projects);
  const { clients } = useSelector((state) => state.clients);
  const isLoadingTimeLogs = useSelector((state) => state.clients.isLoading)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projectModal, setProjectModal] = useState(false);  
  const [flashMessage, setFlashMessage] = useState(false);  
  const { id } = useParams();  
  const { user } = useSelector((state) => state.auth);

  useEffect(() => { 
    dispatch(getProject(id));
    dispatch(getClients(user._id));
  }, [id, dispatch]);


  if (!project) return null;

  const showCreateHandler = (e) => {
    e.preventDefault();
    setProjectModal(true);
  };
  
  const hideCreateHandler = () => {
    setProjectModal(false);
    dispatch({ type: ERROR, payload: [] });   
  };

  return (
    <>
      {projectModal && 
        <ProjectForm 
          clients={clients}
          onClose={hideCreateHandler} 
          setFlashMessage={(flashMessage) => setFlashMessage(flashMessage)} 
          setProjectModal={(projectModal) => setProjectModal(projectModal)} 
          currentId={id}
        />
      }
      
      <div className="pageHeading">
        <div className="titleContainer">
          <h2 className='breadcrumb'><Link to="/projects" className='rootPage'>Projects</Link><span className='breadCrumb__link'>{project.title}</span></h2>
        </div>
        <div className="asideContainer">
          <button onClick={showCreateHandler} type="button" className='button primary'><MdModeEdit className='icon' /><span>Edit project</span></button>        
        </div>
      </div>

      <TimeLogForm 
        projectData={project} 
        currentId={id} 
        projectSelect={false} 
        className='projectDetails'
        singlePage={true}
      />
      
      {isLoadingTimeLogs ? <ProjectsLoader /> : (
      <div className='projectDetails'>
        <div className='projectDetails__inner'>
          <div className="totalInfo" title="Total paid amount per project">
            <span className='label'>PAID AMOUNT</span>
            <span className='hours'>{formatter.format(project.totalPaidAmount)}</span>
          </div>
          <div className="totalInfo" title="Total amount per project">
            <span className='label'>TOTAL AMOUNT</span>
            <span className='hours'>{formatter.format(project.totalAmount)}</span>
          </div>                    
          <div className="totalInfo" title="Total paid hours per project">
            <span className='label'>TOTAL PAID HOURS</span>
            <span className='hours'>{timeFormat(project.paidHours)}</span>
          </div>    
          <div className="totalInfo" title="Total hours per project">
            <span className='label'>TOTAL HOURS</span>
            <span className='hours'>{timeFormat(project.totalHours)}</span>
          </div>
          {/* <h1>desc: {project.description}</h1>     */}                
        </div>
      </div>
      )}
      <TimeLogs projectId={id} />
    </>
  )
}

export default ProjectDetails