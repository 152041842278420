import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteTransaction, getTransactions } from '../../../actions/transactions';
import { formatter, useComponentVisible } from '../../../utils/utils';

const Transaction = ({ transaction, setCurrentId, currentId, setPage, page, pageItems, sort, limit, setTransactionModal }) => {
  let { numberOfPages, transactions } = useSelector((state) => state.transactions);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  page = (pageItems === 1 && numberOfPages > 1) ? page-1 : page;  
  const { 
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  useEffect(() => {
    if(deleteConfirm) {
      if(page) {
        handleDelete();
        dispatch(getTransactions(page, sort, limit, user._id));
        //navigate(`?page=${page}&sort=${sort.sort},${sort.order}`);
      } 
    }

  }, [deleteConfirm, dispatch]);

  const openTransaction = (e) => {
    navigate(`/transactions/${transaction._id}`);
  };  

  const handleEdit = (e) => {
    e.stopPropagation();
    setTransactionModal(true);
    setCurrentId(transaction._id);
    setIsComponentVisible(false);
  };

  const handleDelete = async () => {
    setIsComponentVisible(false);

    dispatch(deleteTransaction({...transaction, currentUser: user?._id}));      
  };


  return (
    <>
      <tr className={`item hoverShowOptions`}>
        <td className='projectDescription'>{transaction.description ? transaction.description : 'No description'}</td>        
        <td>{formatter.format(transaction.amount)}</td>        
        <td className='hideMobile'>{moment(transaction.createdAt).format('MMM DD YYYY')}</td>        
        <td>
          <div className='moreOptions' ref={ref} onClick={(e) => e.stopPropagation()}>            
            { isComponentVisible &&
            <div className='ctas'>
              <ul>
                <li><span className='edit' onClick={handleEdit}>Edit</span></li>
                <li><span className='delete' onClick={() => setDeleteConfirm(true)}>Delete</span></li>
              </ul>
            </div>
            }   
            <span className={`openOptions ${isComponentVisible ? 'open' : ''}`} onClick={(e) => setIsComponentVisible(true)}>
              <BsThreeDotsVertical className='icon' />
            </span>         
          </div>          
        </td>               
      </tr>
    </>
  )
}

export default Transaction