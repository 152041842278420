import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProjects } from '../../actions/projects';
import Project from './Project/Project';
import ProjectForm from './ProjectForm/ProjectForm';
import Pagination from '../Pagination/Pagination';

import styles from './Projects.module.scss';
import { ERROR } from '../../constants/actionTypes';
import { getClients } from '../../actions/clients';
import ProjectsLoader from '../Loaders/ProjectLoader/ProjectsLoader';
import { formatter } from '../../utils/utils';
import { getTimeLogs } from '../../actions/timeLogs';
import { useHooks } from '../../utils/hooks';

const Projects = ({ setProjectModal, projectModal }) => {
  const { dispatch, navigate, page, setPage, query, limit, user } = useHooks();  
  let querySort = query.get('sort') ? query.get('sort') : 'title,asc';
  querySort = querySort.split(",");  
  const [sort, setSort] = useState({ sort: querySort[0], order: querySort[1] });  
  const { projects, project, isLoading, numberOfPages } = useSelector((state) => state.projects);
  const { clients } = useSelector((state) => state.clients);
  const { totalInfo } = useSelector((state) => state.timeLogs);  
  const [currentId, setCurrentId] = useState(0);

  const getClientsCallback = useCallback(() => dispatch(getClients(user._id)), [dispatch, user._id]);
  useEffect(() => {
    getClientsCallback();
  }, [getClientsCallback]);

  useEffect(() => {

  }, [project]);

  useEffect(() => {
    if (page) {
      dispatch(getProjects(page, sort, limit, user._id));
      dispatch(getTimeLogs(page, sort, limit, '', user._id));
      // dispatch(getClients(user._id));
    }
  }, [sort, page, dispatch, navigate]);

	const handleSortBy = (sortVal, orderVal) => {
    const order = orderVal === 'asc' ? 'desc' : 'asc';
    
    setSort((prevState) => ({
      ...prevState,
      sort: sortVal,
      order: order
    }));    
    navigate(`/projects/?page=${page}&sort=${sortVal},${order}`);
	};

  const hideCreateHandler = () => {
    setProjectModal(false);
    setCurrentId(0);
    dispatch({ type: ERROR, payload: [] });   
  };

  const paginationClick = (index) => {
    if(page === index + 1 ) return;
    setPage(index + 1);
    dispatch(getProjects(index + 1, sort, limit, user?._id));
  };

  return (
    <>    
     {/* <ProjectsLoader /> */}
    {projectModal && 
      <ProjectForm 
        clients={clients}
        onClose={hideCreateHandler} 
        setProjectModal={(projectModal) => setProjectModal(projectModal)} 
        currentId={currentId} 
        setCurrentId={(currentId) => setCurrentId(currentId)}
        setPage={(page) => setPage(page)}
        page={page}
        pageItems={projects.length}
        sort={sort}
        setSort={(sort) => setPage(sort)}     
        limit={limit}          
      />
    }
    {isLoading ? <ProjectsLoader /> : (
      <div className='projectDetails'>
        <div className='projectDetails__inner'>
          <div className="totalInfo" title="Total paid hours per project">
            <span className='label'>TOTAL PAID HOURS</span>
            <span className='hours'>{formatter.format(totalInfo?.paidAmount)}</span>
          </div>               
          <div className="totalInfo" title="Total paid amount per project">
            <span className='label'>TOTAL AMOUNT</span>
            <span className='hours'>{formatter.format(totalInfo?.totalAmount)}</span>
          </div>      
          {/* <h1>desc: {project.description}</h1>     */}                
        </div>
      </div>
    )}                  
    {isLoading ? <ProjectsLoader /> : (
      <>
      {!projects.length ? <div className='panel'><h4 className='noData'>No projects</h4></div> : (
      <div className='panel'>  
        <table className={`${styles.projectsList} items-list`}>
          <thead>
            <tr>
              <th><div className={`sortBy ${sort.sort === 'title' ? 'active-'+sort.order : ''}`} onClick={() => handleSortBy('title', sort.order)}>Project</div></th>
              <th className='hideMobile'><div className={`sortBy ${sort.sort === 'client' ? 'active-'+sort.order : ''}`} onClick={() => handleSortBy('client', sort.order)}>Client</div></th>
              <th className='hideTablet'><div className={`sortBy ${sort.sort === 'modifiedAt' ? 'active-'+sort.order : ''}`} onClick={() => handleSortBy('modifiedAt', sort.order)}>Last Update</div></th>
              <th className='hideTablet'><div className={`sortBy ${sort.sort === 'totalHours' ? 'active-'+sort.order : ''}`} onClick={() => handleSortBy('totalHours', sort.order)}>Time status</div></th>
              <th className='hideTablet'><div className={`sortBy ${sort.sort === 'paidHours' ? 'active-'+sort.order : ''}`} onClick={() => handleSortBy('paidHours', sort.order)}>Paid Hours</div></th>
              <th><div>PAID MASTER</div></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
              {projects?.map((project) => (                
                <Project 
                  key={project._id} 
                  className='item' 
                  project={project}
                  setCurrentId={(currentId) => setCurrentId(currentId)} 
                  currentId={currentId}
                  setProjectModal={(projectModal) => setProjectModal(projectModal)} 
                  setPage={(page) => setPage(page)}
                  page={page}
                  pageItems={projects.length}
                  sort={sort}
                  setSort={(sort) => setPage(sort)}     
                  limit={limit}            
                />
              ))}  
          </tbody>     
        </table>
        <Pagination
          setPage={(page) => setPage(page)}
          page={page}
          limit={limit}
          total={projects.length}
          pageItems={projects.length}
          sort={sort}
          setSort={projects.setSort}
          numberOfPages={numberOfPages}
          paginationClick={paginationClick}
        /> 
      </div>
      )}
      </>
    )}
    {/* {!projects.length && !isLoading && <div className='panel'>No projects</div>} */}
    </>
  );
}

export default Projects;