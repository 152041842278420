import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, redirect, useParams } from 'react-router-dom';
import { confirmEmail } from '../../../actions/auth';

import styles from '../Auth.module.scss';

const ConfirmEmail = () => {
    const dispatch = useDispatch();    
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const { errors } = useSelector((state) => state.auth);
    
    useEffect(() => {
        if(token) {
            dispatch(confirmEmail({token}));
        }
    }, []);

    return (
        <div className={`authForm confirmEmail`}>

            {errors?.length ? errors : (  
                <>
                    {!token &&
                        <div>
                            <h3>Almost Done</h3>
                            <p>You will receive an email on your email address. Please click on the link in order to confirm your email address. </p>
                            <p>In case you don't see email please check the spam folder in your email client.</p>
                            <Link element={Link} className="button primary" to='/auth'>Return to Login</Link>
                        </div>
                    }
                    {token &&
                        <div>
                            <h3>Email confirmed!</h3>
                            <p>You can login now and start you journey!</p>
                            <Link element={Link} className="button primary" to='/auth'>Login Now</Link>
                        </div>
                    }
                </>
            )}
        </div>        
    )
}

export default ConfirmEmail