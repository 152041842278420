import { AUTH, ERROR, LOGOUT, START_LOADING, END_LOADING, UPDATE_USER, REMOVE_ACCOUNT } from "../constants/actionTypes";

const authReducer = (state = { authData: null, errors: [], user: JSON.parse(localStorage.getItem('profile')), isLoading: false }, action) => {
    switch (action.type) {
        case AUTH:
            localStorage.setItem('profile', JSON.stringify({ ...action?.data?.user }));
            return { ...state, authData: action.data, loading: false, errors: null, user: JSON.parse(localStorage.getItem('profile')) };
        case LOGOUT:
            localStorage.clear();
            return { ...state, authData: null, loading: false, errors: null, user: false, isLoading: false };
        case UPDATE_USER:
            localStorage.setItem('profile', JSON.stringify({ ...state.user, password: action.payload.updatedUser.password, firstName: action.payload.updatedUser.firstName, lastName: action.payload.updatedUser.lastName }));
            return { ...state, user: {...state.user, password: action.payload.updatedUser.password, firstName: action.payload.updatedUser.firstName, lastName: action.payload.updatedUser.lastName} };
        case ERROR:
            //console.log(action?.payload?.response?.data)
            return { ...state, errors: action?.payload?.response?.data?.message };     
        case START_LOADING:
            return { ...state, isLoading: true };
        case END_LOADING:
            return { ...state, isLoading: false };  
        case REMOVE_ACCOUNT:
            localStorage.clear();
            return { ...state, authData: null, errors: [], user: null, isLoading: false };                                
        default:
            return state;
    }
};

export default authReducer;