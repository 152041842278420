import * as api from '../api';
import { CREATE_CLIENT, DELETE_CLIENT, END_LOADING, ERROR, FETCH_ALL_CLIENTS, FLASH_MESSAGE, START_LOADING, UPDATE_CLIENT } from '../constants/actionTypes';

// export const getClient = (id) => async (dispatch) => {
//     try {
//         dispatch({ type: START_LOADING });

//         const { data } = await api.fetchClient(id);

//         dispatch({ type: FETCH_CLIENT, payload: { clients: data } });
//     } catch (error) {
//         console.log(error);
//     }
// };

export const getClients = (user) => async (dispatch) => {

    try {
        dispatch({ type: START_LOADING });

        const { data } = await api.fetchClients(user);
        dispatch({ type: FETCH_ALL_CLIENTS, payload: data });

        dispatch({ type: ERROR, payload: [] });
        dispatch({ type: END_LOADING });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
};

export const createClient = (client) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.createClient(client);

        dispatch({ type: CREATE_CLIENT, payload: data });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: 'Client added successfully',
            state: 'success'  
        }});

        dispatch({ type: ERROR, payload: [] });
        dispatch({ type: END_LOADING });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
};

export const updateClient = (id, client) => async (dispatch) => {
    try {
        const { data } = await api.updateClient(id, client);
        
        dispatch({ type: UPDATE_CLIENT, payload: data });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: 'Client edited successfully',
            state: 'success'  
        }});  

        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: error.response.data.message,
            state: 'error'  
        }});         
    }
};

export const deleteClient = (client) => async (dispatch) => {
    try {
        await api.deleteClient(client);

        dispatch({ type: DELETE_CLIENT, payload: client._id });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: 'Client removed',
            additionalInfo: client._id,
            state: 'success'  
        }});  

        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
};