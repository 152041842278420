import React, { useId, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './Select.module.scss';

function Select(props) {
  const { options, value, onChange, label, keyvalue, nodata, className} = props;
  const inputId = useId(); 

  return (
    <div className={`${className} formInput ${value ? 'notEmpty' : ''}`}>
        {label && <label htmlFor={inputId}>{label}</label>}
        {!options.length && 
            <div className={styles.noItems}>
                <p>{nodata.textAlert}</p>
                <Link element={Link} to={nodata.ctaLink} className='addItem'>{nodata.ctaText}</Link>
            </div>
        }
        {Number(options.length) > 0 &&
        <select value={value} onChange={onChange} id={inputId} {...props}>
            <option hidden value="">{props.placeholder ?? '-- Select --'}</option>
            {options.map(option => (
                <option key={option._id} value={option._id}>
                    {option[keyvalue]}
                </option>
            ))}
        </select>
        }
    </div>  
  );
}

export default Select;