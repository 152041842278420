const validations = {
    required: {
        rule: () => /\S/,
        errorMessage: "This field is required."
    },
    numeric: {
        rule: () => /^\d+$/,
        errorMessage: "Should contain only numbers."
    }, 
    password: {
        rule: () => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        errorMessage: "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!"
    },
    email: {
        rule: () => /\S+@\S+\.\S+/,
        errorMessage: "It should be a valid email address!"
    },  
    patternmatch: {
        rule: (pattern) => new RegExp('^'+pattern.target.attributes.patternmatch.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')+'$'),
        errorMessage: "Dont match!",  
    },                 
};

export default validations;