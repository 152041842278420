import React from "react"
import ContentLoader from "react-content-loader"

const ProjectsLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1920}
    // height={150}
    viewBox="0 0 906 465"
    backgroundColor="#f3f3f3"
    style={{width: '100%'}}
    foregroundColor="#ecebeb"
    {...props}
  > 
<rect x="1" y="2" rx="3" ry="3" width="906" height="17" /> 
    <circle cx="829" cy="80" r="11" /> 
    <circle cx="864" cy="80" r="11" /> 
    <rect x="54" y="72" rx="3" ry="3" width="141" height="15" /> 
    <rect x="255" y="71" rx="3" ry="3" width="299" height="15" /> 
    <rect x="611" y="71" rx="3" ry="3" width="141" height="15" /> 
    <rect x="5" y="112" rx="3" ry="3" width="897" height="2" /> 
    <circle cx="830" cy="141" r="11" /> 
    <circle cx="865" cy="141" r="11" /> 
    <rect x="55" y="133" rx="3" ry="3" width="141" height="15" /> 
    <rect x="256" y="132" rx="3" ry="3" width="299" height="15" /> 
    <rect x="612" y="132" rx="3" ry="3" width="141" height="15" /> 
    <rect x="6" y="173" rx="3" ry="3" width="897" height="2" /> 
    <circle cx="831" cy="199" r="11" /> 
    <circle cx="866" cy="199" r="11" /> 
    <rect x="56" y="191" rx="3" ry="3" width="141" height="15" /> 
    <rect x="257" y="190" rx="3" ry="3" width="299" height="15" /> 
    <rect x="613" y="190" rx="3" ry="3" width="141" height="15" /> 
    <rect x="7" y="231" rx="3" ry="3" width="897" height="2" /> 
    <circle cx="832" cy="260" r="11" /> 
    <circle cx="867" cy="260" r="11" /> 
    <rect x="57" y="252" rx="3" ry="3" width="141" height="15" /> 
    <rect x="258" y="251" rx="3" ry="3" width="299" height="15" /> 
    <rect x="614" y="251" rx="3" ry="3" width="141" height="15" /> 
    <rect x="8" y="292" rx="3" ry="3" width="897" height="2" /> 
    <circle cx="831" cy="320" r="11" /> 
    <circle cx="866" cy="320" r="11" /> 
    <rect x="56" y="312" rx="3" ry="3" width="141" height="15" /> 
    <rect x="257" y="311" rx="3" ry="3" width="299" height="15" /> 
    <rect x="613" y="311" rx="3" ry="3" width="141" height="15" /> 
    <rect x="7" y="352" rx="3" ry="3" width="897" height="2" /> 
    <circle cx="832" cy="381" r="11" /> 
    <circle cx="867" cy="381" r="11" /> 
    <rect x="57" y="373" rx="3" ry="3" width="141" height="15" /> 
    <rect x="258" y="372" rx="3" ry="3" width="299" height="15" /> 
    <rect x="614" y="372" rx="3" ry="3" width="141" height="15" /> 
    <rect x="5" y="410" rx="3" ry="3" width="897" height="2" /> 
    <rect x="1" y="6" rx="3" ry="3" width="2" height="465" /> 
    <rect x="905" y="6" rx="3" ry="3" width="2" height="465" /> 
    <circle cx="832" cy="441" r="11" /> 
    <circle cx="867" cy="441" r="11" /> 
    <rect x="57" y="433" rx="3" ry="3" width="141" height="15" /> 
    <rect x="258" y="432" rx="3" ry="3" width="299" height="15" /> 
    <rect x="614" y="432" rx="3" ry="3" width="141" height="15" /> 
    <rect x="5" y="470" rx="3" ry="3" width="897" height="2" /> 
    <rect x="2" y="37" rx="3" ry="3" width="906" height="17" /> 
    <rect x="3" y="14" rx="3" ry="3" width="68" height="33" /> 
    <rect x="172" y="11" rx="3" ry="3" width="149" height="33" /> 
    <rect x="494" y="12" rx="3" ry="3" width="137" height="33" /> 
    <rect x="732" y="13" rx="3" ry="3" width="72" height="33" /> 
    <rect x="883" y="11" rx="3" ry="3" width="24" height="33" />
  </ContentLoader>
)

export default ProjectsLoader