import React, { useCallback } from 'react';
import TextInput from '../../UI/Input/TextInput';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { FaArrowRight, FaPlayCircle, FaPlusCircle } from 'react-icons/fa';
import { TiDeleteOutline } from 'react-icons/ti';

import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';
import Timer from '../../Timer/Timer';
import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { createTimeLog, getTimeLogInProgress, updateTimelog, deleteTempTimeLog } from '../../../actions/timeLogs';
import { getProjects } from '../../../actions/projects';
import Select from '../../UI/Select/Select';
import { calcTotalTime } from '../../../utils/utils';
import { useHooks } from '../../../utils/hooks';
import ProjectFormLoader from '../../Loaders/ProjectFormLoader/ProjectFormLoader';

const initialState = { 
    start: {
        time: moment().format("hh:mm A"),
        timeDate: moment().format(),
    },
    end: {
        time: moment().format("hh:mm A"),
        timeDate: moment().format(),
    },    
    date: moment(),
    totalTime: 0,
    taskDescription: '',
    projectId: '',
    hourlyRate: 0,
    totalAmount: 0,
    paidStatus: false
};

const TimeLogForm = ({projectData, currentId, projectSelect, className, singlePage}) => {
    const { dispatch, page, query, limit, user } = useHooks();      
    const { projects, isLoading } = useSelector((state) => state.projects);
    const { lastTimelog, timeLogInProgress } = useSelector((state) => state.timeLogs);
    const [timeLogData, setTimeLogData] = useState({...initialState, projectId: currentId ?? '' });        
    const [project, setProject] = useState(projectData);
    const [openDatepicker, setOpenDatepicker] = useState(false);
    const [timer, setTimer] = useState(0);
    const [timerRunning, setTimerRunning] = useState(false);
    const [timerMode, setTimerMode] = useState(false);    
    const [disableTimer, setDisableTimer] = useState(false);    
    const [timerInProgress, setTimerInProgress] = useState();    
    const [cancelTimer, setCancelTimer] = useState(false);    

    let querySort = query.get('sort') ? query.get('sort') : 'title,asc';
    querySort = querySort.split(",");  
    const [sort, setSort] = useState({ sort: querySort[0], order: querySort[1] });

    const getProjectsCallback = useCallback(() => dispatch(getProjects(page, sort, '', user._id)), [dispatch, page, sort, user._id]);
    useEffect(() => {
        getProjectsCallback();
    }, [getProjectsCallback]);
    
    // useEffect(() => {
    //     timerInProgress ?? setDisableTimer(true);
    // }, [timerInProgress]);

    useEffect(() => {
        setTimeLogData(prevState => { 
            return {...prevState, currentTimelogId: lastTimelog?._id, projectId: currentId ?? lastTimelog?.projectId?._id }
        });
        setTimerInProgress(lastTimelog);

    }, [lastTimelog]);

    useEffect(() => {
        if(timeLogInProgress) {
            const totalTime = calcTotalTime(timeLogInProgress.start, moment().format());
            setTimerMode(true);
            setTimerRunning(true);
            setTimer(totalTime);
            setTimeLogData(prevState => {
                return {
                    ...timeLogInProgress, 
                    currentTimelogId: timeLogInProgress._id, 
                    start: { time: "", timeDate: timeLogInProgress.start }
                }
            });           
            setTimerInProgress(timeLogInProgress);
        }
        
    }, [timeLogInProgress]);

    useEffect(() => {
        setTimeLogData(prevState => { 
            return {...prevState, projectId: project?._id, hourlyRate: project?.hourlyRate }
        });        
        projectData && setProject(projectData);   
    }, [project, projectData]);

    useEffect(() => {
        const currentIdProgress = timerInProgress?.projectId?._id ?? timerInProgress?.projectId;
        if(timerMode) {
            if(singlePage && project?._id && timerMode) {
                if(currentIdProgress !== undefined && (project?._id !== currentIdProgress)) {
                    setDisableTimer(true);
                } else {
                    setDisableTimer(false);
                }
            }
        }

    }, [timerInProgress, project]);

    useEffect(() => {
        dispatch(getTimeLogInProgress(user));

        if(timeLogInProgress) {
            setTimerInProgress(timeLogInProgress);
        }
    }, []);

    useEffect(() => {
        if(timerMode) {
            setTimeLogData(prevState => { 
                return {...prevState, end: { time: "", timeDate: "" } }
            });             
        } else {
            setTimeLogData(prevState => { 
                return {...prevState, end: { time: moment().format("hh:mm A"), timeDate: moment().format() } }
            });    
        }
    }, [timerMode]);

    useEffect(() => {
        if(!timerMode) return;

        const startNow = { time: moment().format("hh:mm A"), timeDate: moment().format() };
        if(timerRunning && !timerInProgress) {            
            setTimeLogData(prevState => { 
                return {...prevState, start: startNow }
            }); 
            dispatch(createTimeLog({...timeLogData, timerMode, start: startNow.timeDate, end: "", projectId: project?._id, totalAmount: 0, creator: user?._id, limit: limit, page: page, sort: sort }));       
        }
        if(!timerRunning && timerInProgress) {
            const totalTime = calcTotalTime(timeLogData.start.timeDate, startNow.timeDate);
            const totalAmount = Number(moment.duration(totalTime, 's').asHours())*Number(timeLogData.hourlyRate);
            dispatch(updateTimelog(timeLogData.currentTimelogId, {taskDescription: timeLogData.taskDescription, date: timeLogData.date, projectId: timeLogData.projectId, totalAmount, totalTime, timerMode, paidStatus: timeLogData.paidStatus, hourlyRate: timeLogData.hourlyRate, end: startNow.timeDate, limit: limit, page: page, sort: sort }));  

            setTimeLogData(prevState => {
                return {
                    ...initialState, 
                    start: startNow,
                    end: startNow
                }
            });
            setTimer(0); 
        }

    }, [timerRunning]);

    useEffect(() => {
        if(cancelTimer && timerRunning) {
            cancelTimerHandler();
        }
    }, [cancelTimer]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if(!timerMode) {
            const dateOneStart = moment(timeLogData.start.timeDate, "YYYY-MM-DDTHH:mm:ss.sss").format("YYYY-MM-DDTHH:mm:ss");
            const dateTwoEnd = moment(timeLogData.end.timeDate, "YYYY-MM-DDTHH:mm:ss.sss").format("YYYY-MM-DDTHH:mm:ss");
            const totalTime = calcTotalTime(dateOneStart, dateTwoEnd);
            const totalAmount = Number(moment.duration(totalTime, 's').asHours())*Number(timeLogData.hourlyRate);        
            dispatch(createTimeLog({...timeLogData, totalTime, start: timeLogData.start.timeDate, end: timeLogData.end.timeDate, timerMode, totalAmount, creator: user?._id, limit: limit, page: page, sort: sort }));
            setTimeLogData({...initialState});   
        }

    }

    const handleChange = (data) => {
        
        if(data.name === "start" || data.name === "end") {
            setTimeLogData(prevState => { 

                return { 
                    ...prevState, 
                    [data.name]: {
                        time: data.value,
                        timeDate: moment(data.value, "hh:mm:ss A").format()
                    }
                }
            });
            return;
        }

        setTimeLogData(prevState => { 
            return { ...prevState, [data.name] : data.value }
        });

    };

    const handleClose = () => {
        setOpenDatepicker(false);
    };

    const cancelTimerHandler = () => {
        dispatch(deleteTempTimeLog({...timerInProgress, currentUser: user._id }));
        setTimer(0);    
        setCancelTimer(false);
        setTimerRunning(false); 
        //setTimeLogData({...initialState, projectId: project?.id ?? '' }); 
        setTimerInProgress();
        setDisableTimer(false);
    };

    return (
        <>
        {isLoading ? <ProjectFormLoader /> :(
            <div className={`timeLogForm ${className ?? ''} ${disableTimer ? 'disableTimer' : ''}`} disabled={disableTimer} title={`${disableTimer ? 'Timer is already active on another project.' : ''}`}>
                <form onSubmit={handleSubmit} className="timeLogForm__inner">
                    <TextInput
                        type="text"
                        value={timeLogData.taskDescription}
                        placeholder="What are you working on?"
                        name="taskDescription"
                        className="taskDescription"
                        events={{
                            onChange: data => handleChange({
                                name: 'taskDescription',
                                value: data.target.value
                            }),
                        }}
                    />
                    {projectSelect &&
                        <Select 
                            name="projectId" 
                            //label="Project"
                            placeholder="Select Project"
                            options={projects} 
                            keyvalue='title'
                            required
                            disabled={timerRunning}
                            title={`${timerRunning ? 'It is not allowed changing project while timer is running.' : '' }`}
                            className="projectId"
                            value={timeLogData.projectId !== '' ? timeLogData.projectId : ''} 
                            nodata={{
                                ctaLink: '/projects',
                                ctaText: 'Add project',
                                textAlert: 'No projects added',
                            }}
                            onChange={(data) => {
                                handleChange({
                                    name: 'projectId',
                                    value: data.target.value
                                });    
                                setProject(projects.find(item => item._id === data.target.value));
                            }} 
                        /> 
                    }        
                    <div className={`startTimeDate ${timerMode ? 'noBorder' : ''}`}> 
                    {!timerMode &&  
                        <TextInput
                            type="text"
                            value={timeLogData.start?.time}
                            validate="required"
                            required
                            placeholder="Start"
                            name="start"
                            className="taskTime taskStartTime"
                            // innerRef={startTime}
                            events={{
                                onClick: data => {
                                    if(!data.target.attributes.focused) {                                    
                                        data.target.setAttribute("focused", true);
                                        data.target.select();
                                    }
                                },         
                                onChange: data => handleChange({
                                    name: 'start',
                                    value: data.target.value,
                                    target: data.target
                                }),
                                onBlur: data => {
                                    handleChange({
                                        name: 'start',
                                        value: moment(data.target.value, "hh:mm A").format("hh:mm A")
                                    });
                                    data.target.removeAttribute("focused");
                                },
                            }}
                        />
                        }
                        <LocalizationProvider  dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                selectedColor="#ff0000"
                                open={openDatepicker}
                                disableFuture={true}
                                onClose={handleClose}
                                inputFormat="MM/DD"
                                value={timeLogData.date}
                                name="date"
                                className={`taskDate ${timerMode && 'hide'}`}    
                                onChange={(newValue) => {
                                    handleChange({
                                        name: 'date',
                                        value: new Date(Date.parse(moment(newValue._d).format("MM/DD/YYYY")))
                                    });
                                    handleClose();
                                }}    
                                renderInput={(params) => <TextField 
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: "Today",                                
                                        //endAdornment: null,
                                    }} 
                                    InputProps={{
                                        endAdornment: null,
                                        placeholder: "Today",
                                    }}
                                                        
                                    onClick={() => setOpenDatepicker(true)}            
                                />}                 
                            />            
                        </LocalizationProvider>
                    </div>
                    {!timerMode && <FaArrowRight className='fromToTimeArrow' /> }
                    {!timerMode && <TextInput
                        type="text"
                        placeholder="End"
                        value={timeLogData.end?.time}
                        name="end"
                        className="taskTime taskEndTime"
                        events={{
                            onClick: data => {
                                if(!data.target.attributes.focused) {                                    
                                    data.target.setAttribute("focused", true);
                                    data.target.select();
                                }
                            },
                            onChange: data => handleChange({
                                name: 'end',
                                value: data.target.value,
                                target: data.target
                            }),
                            onBlur: data => {
                                handleChange({
                                    name: 'end',
                                    value: moment(data.target.value, "hh:mm A").format("hh:mm A")
                                });
                                data.target.removeAttribute("focused");
                            },
                        }}
                    /> 
                    }  
                    <div className='mobileFullWidth'></div>      
                    {timerMode && 
                        <Timer
                            setOpenDatepicker={(openDatepicker) => setOpenDatepicker(openDatepicker)}
                            timeLogData={timeLogData} 
                            timer={timer}
                            setTimer={setTimer}
                            setTimerRunning={(timerRunning) => setTimerRunning(timerRunning)}
                            timerRunning={timerRunning}
                            handleSubmit={handleSubmit}
                        />
                    }
                    { !timerMode && <div><button type="submit" className='submitTimeLog'><FaPlusCircle /></button></div> } 
                    <div className="switchTimeMode">
                        <span 
                            className={`mode ${!timerMode && 'active' }`} 
                            onClick={() => {
                                if(timerRunning) {
                                    setCancelTimer(true);
                                }

                                !timerRunning && setTimerMode(false);
                            }}>
                            {timerRunning ? <TiDeleteOutline className='remove' /> : <FaPlusCircle /> }
                        </span>
                        <span className={`mode ${timerMode && 'active' }`} onClick={() => setTimerMode(true)}><FaPlayCircle /></span>
                    </div> 
                    
                </form>                
            </div>
        )}
        </>        
    )
}

export default TimeLogForm