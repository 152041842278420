import { END_LOADING, FETCH_BY_SEARCH, START_LOADING, FETCH_PROJECT, ERROR, FETCH_ALL_PROJECTS, CREATE_PROJECT, UPDATE_PROJECT, DELETE_PROJECT, REMOVE_ACCOUNT } from "../constants/actionTypes";

const projects = (state = { isLoading: true, project: [], projects: [], errors: [], numberOfPages: [], sortBy: { sort: "title", order: "asc" }, limit: 3 }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };    
      case FETCH_ALL_PROJECTS:
        return {
          ...state,
          projects: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_BY_SEARCH:
        return { ...state, projects: action.payload.data };  
      case FETCH_PROJECT:
        return { ...state, project: action.payload.project };      
      case CREATE_PROJECT:
        return { 
          ...state, 
          projects: action.payload.data,
          // projects: [action.payload, ...state.projects]
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,          
        };
      case UPDATE_PROJECT:
        return {
          ...state,
          project: action.payload, projects: state.projects.map((project) => (project._id === action.payload._id ? action.payload : project))
        };
      case DELETE_PROJECT:
        return { 
          ...state, 
          projects: state.projects.filter((project) => project._id !== action.payload),
        };           
      case ERROR:
          return { ...state, errors: action.payload, isLoading: false };
      case REMOVE_ACCOUNT:
          return { ...state, isLoading: true, project: [], projects: [], errors: [], numberOfPages: [], sortBy: { sort: "title", order: "asc" }, limit: 3 };                             
      default:
        return state;
    }
  };
  
  export default projects;