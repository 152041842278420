import React, { useEffect, useRef, useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { deleteClient, updateClient } from '../../../actions/clients';
import { FLASH_MESSAGE } from '../../../constants/actionTypes';
import { useComponentVisible } from '../../../utils/utils';

import TextInput from '../../UI/Input/TextInput';
import Modal from '../../UI/Modal/Modal';

import styles from './Client.module.scss';

const Client = ({ client }) => {
    const dispatch = useDispatch();    
    const { name } = client; 
    const [updatedName, setUpdatedName] = useState(name);
    const [showEditInput, setShowEditInput] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const { user } = useSelector((state) => state.auth);    
    const { errors } = useSelector((state) => state.projects);
    const [openConfirm, setOpenConfirm] = useState(false); 

    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
      } = useComponentVisible(false);

    useEffect(() => {
        if(errors?.response) { 
            setShowErrors(true);
            setUpdatedName(name);
        };
        // !errors?.response && successMessage && clear();
    }, [errors]);

    useEffect(() => {
        setIsComponentVisible(false);
    }, [openConfirm]);

    const handleChange = (data) => {
        setUpdatedName(data.target.value);
    }

    const handleBlur = async (data) => {
        setUpdatedName(data.target.value);
        if(data.isValid && name !== updatedName) {
            dispatch(updateClient(client._id, {...client, name: updatedName, oldName: client.name, currentUser: user?._id}));
            setSuccessMessage(true);
        }
        // name !== updatedName && setUpdatedName(data.target.value);
        setShowEditInput(false); 
    }

    const handleEdit = () => {
        setShowEditInput(true);
        setIsComponentVisible(false);
    };

    const handleDelete = async () => {
        if(openConfirm) {
            dispatch(deleteClient({...client, currentUser: user?._id}));
            setIsComponentVisible(false); 
        }
        setOpenConfirm(!openConfirm);
    };

    const onClose = () => {
        setOpenConfirm(false);
    }

    return (
        <>
        {openConfirm && 
            <Modal onClose={onClose} title="Delete client">
                <div className="modal__content">
                    <p>You are about to delete <b>{client.name}</b>.</p>
                    <div className="modal__warning">
                        <p><b>This action cannot be reversed</b></p>
                        <p>Deleting a client will permanently remove the client from all associated time entries and projects.</p>
                    </div>
                </div>
                <div className="modal__footer footer__multi-button">
                    <button className='button danger full-width' onClick={handleDelete}>Delete</button>
                    <button className='button full-width' onClick={() => setOpenConfirm(false)}>Cancel</button>
                </div>
            </Modal>
          }             
        <div className={`${styles.item} ${showEditInput ? styles.open : ''}`} title={updatedName}  >
            {!showEditInput && <span onClick={() => setIsComponentVisible(true)} className={styles.clientName}>{updatedName}</span>}
            {isComponentVisible &&
            <div className='moreOptions' ref={ref}>
                <div className='ctas'>
                <ul>
                    <li><span className='edit' onClick={handleEdit}>Edit</span></li>
                    <li><span className='delete' onClick={handleDelete}>Delete</span></li>
                </ul>
                </div> 
            </div> 
            }           
            {showEditInput &&
            <TextInput                
                type="text"
                id="name"
                name="name"
                className={styles.formInput}
                placeholder="Name"
                validate="required"
                value={updatedName}
                required
                autoFocus={true}
                events={{
                    onChange: data => handleChange(data),
                    onBlur: data => handleBlur(data),
                    onKeyDown: data => (data.key === 'Enter') && data.target.blur()
                }}
            />            
            }     
            {!showEditInput &&     
            <span className={`openOptions ${styles.openOptions}`} onClick={(e) => setIsComponentVisible(true)}>
                <BsThreeDotsVertical className='icon' />
            </span>
            }
        </div>
        </>
    )
}

export default Client