import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { updateUser } from '../../actions/users';
import TextInput from '../UI/Input/TextInput'

const UserProfile = ({user}) => {
  const dispatch = useDispatch();  
  const [formData, setFormData] = useState({firstName: user.firstName, email: user.email, lastName: user.lastName, userId: user._id});

  const handleChange = (e) => {
    setFormData(prevState => { 
      return { ...prevState, [e.target.name] : e.target.value }
    });
  };

  const handleSubmit = async (data) => {
    //e.preventDefault();
    if(user[data.target.name] === data.target.value || !data.target.value) return;
    dispatch(updateUser(formData));
  };  

  return (
    <form onKeyDown={(e) => {if (e.key === 'Enter') handleSubmit(e)}}>               
      <TextInput
        type="text"
        id="name"
        name="firstName"
        placeholder="Name"
        validate="required"
        value={formData?.firstName}
        label="Name"
        required
        events={{
          onChange: data => handleChange(data),
          onBlur: data => handleSubmit(data),
        }}
      />
      <TextInput
        type="text"
        label="Last Name"
        id="lastname"
        name="lastName" 
        placeholder="Last Name"
        validate="required"
        value={formData?.lastName}       
        required
        events={{
          onChange: data => handleChange(data),
          onBlur: data => handleSubmit(data),
        }}
      />     
      <TextInput
        type="text"
        label="Email (can not be changed)"
        id="email" 
        placeholder="Email" 
        name="email" 
        validate="email"
        value={formData?.email}    
        required
        disabled
        events={{
          //onChange: data => handleChange(data),
          //onBlur: () => handleSubmit(),
        }}        
      />              
    </form>
  )
}

export default UserProfile