import * as api from '../api';
import { CLEAR_TEMP_LOGS, CLEAR_TIMELOGS, CREATE_TIMELOG, DELETE_TIMELOG, END_LOADING, ERROR, FETCH_ALL_TIMELOGS, FETCH_TIMELOG_IN_PROGRESS, FLASH_MESSAGE, START_LOADING, UPDATE_PROJECT, UPDATE_TIMELOG, UPDATE_TOTAL } from "../constants/actionTypes";

export const getTimeLogs = (page, sort, limit, projectId, user) => async (dispatch) => {
    dispatch({ type: START_LOADING });
    try {
        projectId && dispatch({ type: CLEAR_TIMELOGS });

        const { data } = await api.fetchTimeLogs(page, sort, limit, projectId, user);

        dispatch({ type: FETCH_ALL_TIMELOGS, payload: data });
        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
    dispatch({ type: END_LOADING });    
};

export const createTimeLog = (timelog) => async (dispatch) => {
    !timelog.timerMode && dispatch({ type: START_LOADING });    

    try {
        const { data } = await api.createTimeLog(timelog);
        
        dispatch({ type: CREATE_TIMELOG, payload: {...data, timerMode: timelog.timerMode} });
        dispatch({ type: UPDATE_PROJECT, payload: data.updatedProject });      

        !timelog.timerMode && dispatch({ type: FLASH_MESSAGE, payload: {
            message: 'Timelog successfully added',
            state: 'success'  
        }});    
                      
        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
    !timelog.timerMode && dispatch({ type: END_LOADING });    
};

export const updateTimelog = (id, timelog) => async (dispatch) => {
    timelog.timerMode && dispatch({ type: START_LOADING });    

    try {
        const { data } = await api.updateTimeLog(id, timelog);

        dispatch({ type: UPDATE_TIMELOG, payload: {updatedTimelog: data.updatedTimelog, timerMode: timelog.timerMode} });
        dispatch({ type: UPDATE_PROJECT, payload: data.updatedProject });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: timelog.timerMode ? 'Timelog successfully added' : 'Time Log updated',
            state: 'success'  
        }});     

        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
    timelog.timerMode && dispatch({ type: END_LOADING });     
};

export const updateProjectTimeLogsPaidStatus = (timelogs) => async (dispatch) => {
    dispatch({ type: START_LOADING });    
    try {
        const { data } = await api.updateProjectTimeLogsPaidStatus(timelogs);
               
        dispatch({ type: UPDATE_PROJECT, payload: data.updatedProject });
        dispatch({ type: UPDATE_TOTAL, payload: data });
        
        dispatch({ type: FLASH_MESSAGE, payload: {
            message: `Time Logs for ${data.updatedProject.title} successfully updated`,
            state: 'success'  
        }});   

        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
    dispatch({ type: END_LOADING });     
};

export const deleteTimeLog = (timelog) => async (dispatch) => {
    dispatch({ type: START_LOADING });    
    try {
        console.log(timelog)
        const { data } = await api.deleteTimeLog(timelog);

        dispatch({ type: DELETE_TIMELOG, payload: data });
        dispatch({ type: UPDATE_PROJECT, payload: data.updatedProject });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: 'Time Log removed',
            state: 'success'  
        }});       

        dispatch({ type: ERROR, payload: [] });        
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
    dispatch({ type: END_LOADING });     
};

export const deleteTempTimeLog = (timelog) => async (dispatch) => {  
    //dispatch({ type: START_LOADING }); 
    try {
        const { data } = await api.deleteTempTimeLog(timelog);
        dispatch({ type: CLEAR_TEMP_LOGS, payload: data });               
        dispatch({ type: ERROR, payload: [] });        
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    } 
    //dispatch({ type: END_LOADING });
};

export const getTimeLogInProgress = (user) => async (dispatch) => {
    // dispatch({ type: START_LOADING });    
    try {

        const { data } = await api.fetchTimeLogInProgress(user);
        dispatch({ type: FETCH_TIMELOG_IN_PROGRESS, payload: data });

        dispatch({ type: ERROR, payload: [] });        
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
    //dispatch({ type: END_LOADING });     
};