import ReactDOM from 'react-dom';

const Backdrop = (props) => {
  return <div className='backdrop' onClick={props.onClose}/>;
};

const ModalOverlay = (props) => {
  return (
    <div className={props.className}>
      <div className='modal__wrap'>{props.children}</div>
    </div>
  );
};

const portalElement = document.getElementById('overlays');

const Modal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
      {ReactDOM.createPortal(
        <ModalOverlay className='modal'>
          <div className='modal__head'>
            {props.title && <h4 className='modal__title'>{props.title}</h4>}
            <span className='modal__close' onClick={props.onClose}></span>
          </div>          
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default Modal;