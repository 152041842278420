import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { getClients } from '../../actions/clients';
import { ERROR } from '../../constants/actionTypes';
import { useHooks } from '../../utils/hooks';
import ProjectsLoader from '../Loaders/ProjectLoader/ProjectsLoader';
import Client from './Client/Client';
import ClientForm from './ClientForm/ClientForm'
import styles from './Clients.module.scss';

const Clients = ({ setClientModal, clientModal }) => {
  const { dispatch, user } = useHooks();  
  const { clients, isLoading } = useSelector((state) => state.clients);  

  const hideCreateHandler = () => {
    setClientModal(false);
    dispatch({ type: ERROR, payload: [] });   
  };

  useEffect(() => {
      dispatch(getClients(user._id));
  }, [dispatch]);

  return (
    <>
    {clientModal && 
      <ClientForm
        onClose={hideCreateHandler} 
        setProjectModal={(projectModal) => setClientModal(projectModal)}         
      />
    }     
    {isLoading ? <ProjectsLoader /> : (
      <>
      {!clients.length ? <div className='panel'><h4 className='noData'>No clients</h4></div> : (
        <div className='panel clients'>
          <div className={styles.clientsList}>
            {clients?.map((client) => (
              <Client 
                key={client._id} 
                client={client}      
              />
            ))}     
          </div>
        </div>
      )}
      </>
    )}       
    </>
  )
}

export default Clients