import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { formatter, timeFormat, useComponentVisible } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTimeLog, getTimeLogs, updateTimelog } from '../../../actions/timeLogs';
import { useNavigate } from 'react-router-dom';

import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdPaid, MdOutlinePaid } from 'react-icons/md';
import { FLASH_MESSAGE } from '../../../constants/actionTypes';

const TimeLog = ({timeLog, page, setPage, sort, limit, pageItems, numberOfPages, showProjectTitle, projectId}) => {
  const dispatch = useDispatch();  
  const navigate = useNavigate();   
  const { user } = useSelector((state) => state.auth);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  page = (pageItems === 1 && numberOfPages > 1) ? page-1 : page;  
  const { 
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  useEffect(() => { 
    if(deleteConfirm) {      
      handleDelete();
      dispatch(getTimeLogs(page, sort, limit, projectId, user._id));
    } 

  }, [deleteConfirm, page, dispatch]);

  const handleDelete = async () => {
    dispatch(deleteTimeLog({...timeLog, projectId: timeLog.projectId._id, currentUser: user?._id}));
    setPage(page);
    navigate(`?page=${page}&sort=${sort.sort},${sort.order}`);    
    setDeleteConfirm(false);      
  };  

  const handlePaidStatus = async () => {
    setIsLoading(true);
    dispatch(updateTimelog(timeLog._id, {...timeLog, paidStatus: !timeLog.paidStatus })).then(() => {
      setIsLoading(false);   
    });   
       
  };    

  return (
    <tr className={`item hoverShowOptions`}>
      { showProjectTitle && <td className='projectName hideMobile'>{timeLog.projectId.title}</td> }
      <td className='projectDescription'>{timeLog.taskDescription || "No description"}</td>
      <td className='hideTablet hideMobile'>{moment(timeLog.date).format('MMM DD YYYY')}</td>
      <td className='hideMobile'>{timeFormat(timeLog.totalTime)} h</td>
      <td className='hideMobile'>{formatter.format(timeLog.totalAmount)}</td>
      <td><button title="Mark time log as paid" onClick={handlePaidStatus} disabled={isLoading} className={`paidStatus ${timeLog.paidStatus ? 'paid' : 'notpaid'}`}>{timeLog.paidStatus ? <MdPaid />  : <MdOutlinePaid /> }</button></td>
      <td>
          <div className='moreOptions' ref={ref} onClick={(e) => e.stopPropagation()}>            
            { isComponentVisible &&
            <div className='ctas'>
              <ul>
                <li><span className='delete' onClick={() => setDeleteConfirm(true)}>Delete</span></li>
              </ul>
            </div>
            }   
            <span className={`openOptions ${isComponentVisible ? 'open' : ''}`} onClick={(e) => setIsComponentVisible(true)}>
              <BsThreeDotsVertical className='icon' />
            </span>         
          </div>          
        </td>       
    </tr>
  )
}

export default TimeLog