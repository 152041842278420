import * as api from '../api';
import { END_LOADING, ERROR, FLASH_MESSAGE, REMOVE_ACCOUNT, START_LOADING, UPDATE_USER } from "../constants/actionTypes";

export const updateUser = (user) => async (dispatch) => {
    dispatch({ type: START_LOADING });
    try {
        const { data } = await api.updateUser(user);

        dispatch({ type: UPDATE_USER, payload: data });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: 'User updated',
            state: 'success'  
        }});

        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
    dispatch({ type: END_LOADING });
};

export const removeAccount = (user) => async (dispatch) => {
    dispatch({ type: START_LOADING });

    try {
        const { data } = await api.removeAccount(user);

        dispatch({ type: REMOVE_ACCOUNT, payload: [] });

        dispatch({ type: FLASH_MESSAGE, payload: {
            message: 'Account removed. Goodbye!',
            state: 'success'  
        }});

        dispatch({ type: ERROR, payload: [] });
    } catch (error) {
        dispatch({ type: ERROR, payload: error });
    }
    dispatch({ type: END_LOADING });
};