import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createTransaction, updateTransaction } from '../../../actions/transactions'
import ComponentLoader from '../../Loaders/ComponentLoader/ComponentLoader'
import TextInput from '../../UI/Input/TextInput'
import Modal from '../../UI/Modal/Modal'

const initialState = { 
  description: '',
  creator: '',
  amount: null,     
};

const TransactionForm = ({ currentId, setCurrentId, onClose, page, sort, limit, setTransactionModal }) => {
  const [transactionData, setTransactionData] = useState(initialState);
  const [showErrors, setShowErrors] = useState(false);
  const { errors, isLoading } = useSelector((state) => state.transactions);
  const [successMessage, setSuccessMessage] = useState(false);  
  const transaction = useSelector((state) => (currentId ? state.transactions.transactions.find((message) => message._id === currentId) : null));
  const dispatch = useDispatch();
  const editMode = (currentId !== 0);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (transaction) setTransactionData(transaction);
  }, [transaction, dispatch, editMode]);

  useEffect(() => {
    errors?.response && setShowErrors(true);
    !errors?.response && successMessage && clear();
  }, [errors]);

  useEffect(() => {
    if(showErrors) {
      setTimeout( () => setShowErrors(false), 3000);
    }
  }, [showErrors]);

  const clear = () => {
    !editMode && setCurrentId(0);
    setTransactionData(initialState);
    setTransactionModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!editMode) {
      dispatch(createTransaction({...transactionData, creator: user?._id, limit: limit, page: page, sort: sort}));
    } else {
      dispatch(updateTransaction(currentId, {...transactionData, currentUser: user?._id}));
    }

    setSuccessMessage(true);
  };    

  const handleChange = (e) => {
    setTransactionData(prevState => { 
      return { ...prevState, [e.target.name] : e.target.value }
    });
  }

  return (
    <>
    <Modal onClose={onClose} title={editMode ? 'Edit transaction' : 'Add new transaction'}>
      <form onSubmit={handleSubmit}>
        {isLoading && <ComponentLoader />}  
        <TextInput
          type="number"
          id="amount"
          name="amount"
          placeholder="Amount"
          label="Amount"
          validate="numeric"
          value={transactionData.amount ?? ''}
          required
          events={{
            onChange: data => handleChange(data)
          }}
        />  
        <TextInput
          type="textarea"
          id="description"
          name="description"
          label="Description"
          placeholder="Description"
          value={transactionData.description}
          events={{
            onChange: data => handleChange(data)
          }}
        />                       
        <button type='submit' className='button primary full-width'>{editMode ? 'Edit transaction' : 'Add new transaction'}</button>    
        {errors?.message && showErrors && <div className='form__global-errors'>{errors.response.data.message}</div>}
      </form>
      
    </Modal>
    </>
  )
}

export default TransactionForm