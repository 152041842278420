import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import decode from 'jwt-decode';

import { BiTimeFive } from 'react-icons/bi';
import { AiFillSetting, AiOutlineFolder } from 'react-icons/ai';
import { MdOutlineLogout } from 'react-icons/md';
import { CgUserList } from 'react-icons/cg';
import { TbCoin } from 'react-icons/tb';

import styles from './Sidebar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT } from '../../constants/actionTypes';
import { BsFillRecordCircleFill } from 'react-icons/bs';
import { getTimeLogInProgress } from '../../actions/timeLogs';
import { useComponentVisible } from '../../utils/utils';

const Sidebar = ({showSidebar, setShowSidebar}) => {
  const { user } = useSelector((state) => state.auth);
  const { timeLogInProgress, lastTimelog } = useSelector((state) => state.timeLogs);  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTimer, setActiveTimer] = useState(false);

  const logout = () => {
      dispatch({ type: LOGOUT });
      navigate('/auth');
  };

  useEffect(() => { 
      const token = user?.token;

      if (token) {
          const decodedToken = decode(token);
          if (decodedToken.exp * 1000 < new Date().getTime()) logout();
      }
  }, [location]);
  
  useEffect(() => {
    dispatch(getTimeLogInProgress(user));
  }, []);

  useEffect(() => {
    if(timeLogInProgress !== null || lastTimelog !== null) {
      setActiveTimer(true);
    } else {
      setActiveTimer(false);
    }
  }, [timeLogInProgress, lastTimelog]);

  return (
    <>
    <div className={`${styles.overlay} ${showSidebar ? styles.showSidebar: ''}`} onClick={() => setShowSidebar(false)}></div>
    <div className={`${styles.sidebar} ${showSidebar ? styles.showSidebar: ''}`}>
      <div className={styles.topContent}>
        <span className={styles.welcomeMessage} title={`Hello ${user?.firstName}`}>Hello {user?.firstName}</span>
        <nav className={styles.navbar}>
          <ul>
          {user ? (
            <>
              <li className={styles.ulSection}>
                <span className={styles.ulHeading}>TRACK</span>
                <ul>
                  <li title={`${activeTimer ? 'Timer is running.' : ''}`}>
                    <NavLink onClick={() => setShowSidebar(false)} element={Link} to='/timer' className={({ isActive }) => (isActive ? styles.active : '')}>
                      <BiTimeFive className={styles.icon} /> <span className={styles.label}>Timer</span>
                      {activeTimer && <span className={styles.timerOnIcon}><BsFillRecordCircleFill /></span> }
                    </NavLink>
                  </li>
                </ul>            
              </li>
              <li className={styles.ulSection}>
                <span className={styles.ulHeading}>MANAGE</span>
                <ul>
                  <li><NavLink onClick={() => setShowSidebar(false)} element={Link} to='/projects' className={({ isActive }) => (isActive ? styles.active : '')}><AiOutlineFolder className={styles.icon} /><span className={styles.label}>Projects</span></NavLink></li>
                  <li><NavLink onClick={() => setShowSidebar(false)} element={Link} to='/clients' className={({ isActive }) => (isActive ? styles.active : '')}><CgUserList className={styles.icon} /><span className={styles.label}>Clients</span></NavLink></li>
                  <li><NavLink onClick={() => setShowSidebar(false)} element={Link} to='/transactions' className={({ isActive }) => (isActive ? styles.active : '')}><TbCoin className={styles.icon} /><span className={styles.label}>Transactions</span></NavLink></li>   
                </ul>
              </li>
            </>
            ) : (
              <button><Link element={Link} to='/auth'>Auth</Link></button>
            )}        
          </ul>
        </nav>        
      </div>
      <div className={styles.bottomContent}>
        <nav className={styles.navbar}>
          <span className={styles.ulHeading}>ADMIN</span>
          <ul>
            <li><NavLink onClick={() => setShowSidebar(false)} element={Link} to='/settings' className={({ isActive }) => (isActive ? styles.active : '')}><AiFillSetting className={styles.icon} /><span className={styles.label}>Settings</span></NavLink></li>
            <li><NavLink onClick={() => { setShowSidebar(false); logout(); }} element={Link} ><MdOutlineLogout className={styles.icon} /><span className={styles.label}>Logout</span></NavLink></li>
          </ul>
        </nav>        
      </div>        
    </div>
    </>
  )
}

export default Sidebar