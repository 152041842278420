import React from 'react'
import { useSelector } from 'react-redux';
import PasswordChange from '../../components/PasswordChange/PasswordChange';
import RemoveAccount from '../../components/RemoveAccount/RemoveAccount';
import UserDefaults from '../../components/UserDefaults/UserDefaults';
import UserProfile from '../../components/UserProfile/UserProfile'

const SettingsPage = () => {
    const { user } = useSelector((state) => state.auth);

    return (
        <>
        <div className="pageHeading">
        <div className="titleContainer">
            <h2>Settings</h2>
        </div>
        </div> 
        <div className='card'>
            <div className="card__inner">
                <div className="card__heading">
                    <h4>Profile preferences</h4>
                    <p>You can change personal user data</p>
                </div>
                <div className="card__content">
                    <UserProfile user={user} />
                </div>
            </div>
        </div>
        <div className='card'>
            <div className="card__inner">
                <div className="card__heading">
                    <h4>Default billing set-up</h4>
                    <p>These settings will be used if not defined otherwise on a team member or Project level</p>
                </div>
                <div className="card__content">
                    <UserDefaults user={user} />
                </div>
            </div>
        </div>          
        <div className='card'>
            <div className="card__inner">
                <div className="card__heading">
                    <h4>Account actions</h4>
                </div>
                <div className="card__content">
                    <PasswordChange user={user} />
                    <RemoveAccount user={user} />
                </div>
            </div>
        </div>                     
        </>    
    )
}

export default SettingsPage