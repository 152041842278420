import { FLASH_MESSAGE, REMOVE_ACCOUNT } from "../constants/actionTypes";

const shared = (state = { isLoading: true, errors: [], flashMessage: [] }, action) => {
    switch (action.type) {
      case FLASH_MESSAGE:
        return {
            ...state,
            flashMessage: action.payload
         };
      case REMOVE_ACCOUNT:
        return { isLoading: false, errors: [], flashMessage: [] };  
      default:
        return state;
    }
  };
  
  export default shared;